.p-policy {
	.l-titarea h1 {
		max-width: 600px;
	}
	.l-article {
		a:not([class]) {
			margin-bottom: 0;
			margin-left: 0;
		}
		ul:not([class])>li::before {
			top: 10px;
			@include media(m) {
				top: 2vw;
			}
		}
		p:not([class]) + ul{
			margin-top: 20px;
			@include media(m) {
				margin-top: 1vw;
			}
		}
		h3 {
			@include media(m) {
				margin-top: 12.53333vw;
			}
		}
		.u-aR + p {
			@include media(m) {
				margin-top: 8.53333vw;
				+ p {
					margin-top: 8.53333vw;
				}
			}
		}
	}
}