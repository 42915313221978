@charset "UTF-8";

@for $value from 0 through 10 {
	.u-mt#{$value * 10} {
		margin-top: 10px * $value!important;
	}
	.u-mb#{$value * 10} {
		margin-bottom: 10px * $value!important;
	}
	.u-mr#{$value * 10} {
		margin-right: 10px * $value!important;
	}
	.u-ml#{$value * 10} {
		margin-left: 10px * $value!important;
	}
}
