a.c-link {
	border-bottom: 1px solid #282828;
	display: inline-block;
	position: relative;
	line-height: 1.8em;
	margin: 0 15px 10px 15px;
	font-weight: 400;
	&:hover {
		border-bottom: 1px solid #fff;
	}
	&[href^="tel:"] {
		pointer-events: none;
		border: none;
		cursor: default;
		margin: 0;
	}
	&[target="_blank"] {
		i {
			width: 11px;
			height: 8px;
			position: relative;
			display: inline-block;
			margin-left: 8px;
			border: 1px solid #282828;
			transform: translate(0,-2px);
			&:before {
				content: '';
				width: 11px;
				height: 8px;
				border-bottom: 1px solid #282828;
				border-left: 1px solid #282828;
				position: absolute;
				bottom: -3px;
				left: -3px;
			}
		}
		&[href$=".pdf"] {
			i {
				width: 11px;
				height: 13px;
				position: relative;
				display: inline-block;
				margin-left: 8px;
				&:before {
					content: '';
					display: block;
					width: 11px;
					height: 13px;
					right: 0;
					position: absolute;
					background: url(/common/img/ico_pdf_black.png) center bottom/cover no-repeat;
				}
			}
		}
	}
	@include media(m) {
		margin: 0 vw(5);
		&[href^="tel:"] {
			pointer-events: auto;
			border-bottom: 1px solid #282828;
			margin: 0 vw(5);
		}
		&[target="_blank"] {
			i {
				width: vw(24);
				height: vw(18);
				margin-left: vw(10);
				&:before {
					width: vw(24);
					height: vw(18);
					bottom: -3px;
					left: -3px;
				}
			}
			&[href$=".pdf"] {
				i {
					width: vw(20);
					height: vw(25);
					margin-left: vw(10);
					&:before {
						width: vw(20);
						height: vw(25);
					}
				}
			}
		}
	}


}
