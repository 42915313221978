.c-btnlist {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	li {
		width: calc((100% - (16px * 4)) / 5);
		margin-bottom: 14px;
		@include media(m) {
			&.-empty {
				display: none;
			}
		}
		> span,a {
			background: #f1f1f1;
			border-radius: 2px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 10px;
			height: 58px;
			color: #282828;
			cursor: pointer;
			span {
				display: block;
				font-size: 1.4rem;
				width: 118px;
				text-align: center;
				line-height: 1.3;
			}
			&.is-active {
				background:$blue;
				color: #fff;
				img {
					opacity: 0;
				}
			}
			@include media(hover) {
				&:hover {
					background:$blue;
					color: #fff;
					img {
						opacity: 0;
					}
				}
			}
		}
	}
	i {
		display: block;
		margin-right: 11px;
		img {
			display: block;
		}
		&.ico-eye {
			width: 33px;
			height: 20px;
			background: url(/common/img/svgico/function/white/ico_eye.svg) center center/100% no-repeat;
			img {
				width: 33px;
			}
		}
		&.ico-anti {
			width: 39px;
			height: 30px;
			background: url(/common/img/svgico/function/white/ico_anti.svg) center center/100% no-repeat;
			img {
				width: 39px;
			}
		}
		&.ico-immunity {
			width: 29px;
			height: 33px;
			background: url(/common/img/svgico/function/white/ico_immunity.svg) center center/100% no-repeat;
			img {
				width: 29px;
			}
		}
		&.ico-skin {
			width: 36px;
			height: 33px;
			background: url(/common/img/svgico/function/white/ico_skin.svg) center center/100% no-repeat;
			img {
				width: 36px;
			}
		}
		&.ico-diet {
			width: 27px;
			height: 30px;
			background: url(/common/img/svgico/function/white/ico_diet.svg) center top/cover no-repeat;
			img {
				width: 27px;
			}
		}
		&.ico-joint {
			width: 34px;
			height: 34px;
			background: url(/common/img/svgico/function/white/ico_joint.svg) center center/100% no-repeat;
			img {
				width: 34px;
			}
		}
		&.ico-brain {
			width: 29px;
			height: 36px;
			background: url(/common/img/svgico/function/white/ico_brain.svg) center center/100% no-repeat;
			img {
				width: 29px;
			}
		}
		&.ico-men {
			width: 33px;
			height: 35px;
			background: url(/common/img/svgico/function/white/ico_men.svg) center center/100% no-repeat;
			img {
				width: 33px;
			}
		}
		&.ico-woman {
			width: 32px;
			height: 33px;
			background: url(/common/img/svgico/function/white/ico_woman.svg) center center/100% no-repeat;
			img {
				width: 32px;
			}
		}
		&.ico-sports {
			width: 30px;
			height: 30px;
			background: url(/common/img/svgico/function/white/ico_sports.svg) center center/100% no-repeat;
			img {
				width: 30px;
			}
		}
		&.ico-super-foods {
			width: 32px;
			height: 30px;
			background: url(/common/img/svgico/function/white/ico_super-foods.svg) center center/100% no-repeat;
			img {
				width: 32px;
			}
		}
		&.ico-plantprotein {
			width: 39px;
			height: 29px;
			background: url(/common/img/svgico/function/white/ico_plantprotein.svg) center center/100% no-repeat;
			img {
				width: 39px;
			}
		}
		&.ico-otherprotein {
			width: 39px;
			height: 23px;
			background: url(/common/img/svgico/function/white/ico_otherprotein.svg) center center/100% no-repeat;
			img {
				width: 39px;
			}
		}
		&.ico-plantoil {
			width: 31px;
			height: 37px;
			background: url(/common/img/svgico/function/white/ico_plantoil.svg) center center/100% no-repeat;
			img {
				width: 31px;
			}
		}
		&.ico-sweetener {
			width: 34px;
			height: 34px;
			background: url(/common/img/svgico/function/white/ico_sweetener.svg) center center/100% no-repeat;
			img {
				width: 34px;
			}
		}
		&.ico-flavor {
			width: 32px;
			height: 30px;
			background: url(/common/img/svgico/function/white/ico_flavor.svg) center center/100% no-repeat;
			img {
				width: 32px;
			}
		}
		&.ico-concentratedjuice {
			width: 35px;
			height: 35px;
			background: url(/common/img/svgico/function/white/ico_concentratedjuice.svg) center center/100% no-repeat;
			img {
				width: 35px;
			}
		}
		&.ico-millet {
			width: 35px;
			height: 35px;
			background: url(/common/img/svgico/function/white/ico_millet.svg) center center/100% no-repeat;
			img {
				width: 35px;
			}
		}
		&.ico-lecithin {
			width: 43px;
			height: 38px;
			background: url(/common/img/svgico/function/white/ico_lecithin.svg) center center/100% no-repeat;
			img {
				width: 43px;
			}
		}
		&.ico-alternative {
			width: 38px;
			height: 26px;
			background: url(/common/img/svgico/function/white/ico_alternative.svg) center center/100% no-repeat;
			img {
				width: 38px;
			}
		}
		&.ico-naturalcolor {
			width: 35px;
			height: 33px;
			background: url(/common/img/svgico/function/white/ico_naturalcolor.svg) center center/100% no-repeat;
			img {
				width: 35px;
			}
		}
	}
	img {
		display: block;
		transition-duration: 0.3s;
		vertical-align: baseline;
	}
	@include media(m) {
		&:before,&:after {
			content: '';
			width: vw(208);
			display: block;
		}
		&:before {
			order: 1;
		}
		li {
			width: vw(208);
			margin-bottom: vw(10);
			> span,a {
				border-radius: vw(2);
				padding: vw(10) vw(10) vw(10) vw(10);
				height: vw(130);
				justify-content: center;
				flex-wrap: wrap;
				span {
					font-size: vw(24);
					line-height: 1.2;
				}
			}
		}
		i {
			height: auto!important;
			width: auto!important;
			display: flex;
			align-items: center;
			margin: vw(8) 0 vw(5) 0;
			&.ico-eye {
				img {
					width: vw(56);
				}
			}
			&.ico-anti {
				img {
					width: vw(60);
				}
			}
			&.ico-immunity {
				img {
					width: vw(36);
				}
			}
			&.ico-skin {
				img {
					width: vw(51);
				}
			}
			&.ico-diet {
				transform: translateY(1vw);
				margin-top: 0;
				img {
					width: vw(40);
				}
			}
			&.ico-joint {
				margin-top: 0;
				img {
					width: vw(46);
				}
			}
			&.ico-brain {
				img {
					width: vw(40);
				}
			}
			&.ico-men {
				img {
					width: vw(46);
				}
			}
			&.ico-woman {
				img {
					width: vw(44);
				}
			}
			&.ico-sports {
				margin-top: 0;
				img {
					width: vw(45);
				}
			}
			&.ico-super-foods {
				img {
					width: vw(45);
				}
			}
			&.ico-plantprotein {
				img {
					width: vw(57);
				}
			}
			&.ico-otherprotein {
				img {
					width: vw(56);
				}
			}
			&.ico-plantoil {
				img {
					width: vw(45);
				}
			}
			&.ico-sweetener {
				img {
					width: vw(50);
				}
			}
			&.ico-flavor {
				img {
					width: vw(47);
				}
			}
			&.ico-concentratedjuice {
				img {
					width: vw(51);
				}
			}
			&.ico-millet {
				img {
					width: vw(50);
				}
			}
			&.ico-lecithin {
				img {
					width: vw(63);
				}
			}
			&.ico-alternative {
				img {
					width: vw(54);
				}
			}
			&.ico-naturalcolor {
				img {
					width: vw(51);
				}
			}
		}
	}
}