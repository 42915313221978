.l-titarea {
	margin-bottom: 82px;
	padding: 55px 0 65px 0;
	position: relative;
	z-index: 5;
	min-height: 270px;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	background: url(/common/img/titarea/tit_product_pc.jpg) center center/cover no-repeat;
	@media screen and (min-width: 1501px) {
		background: url(/common/img/titarea/tit_product_pc.jpg) center center/cover no-repeat;
	}
	@include media(m) {
		background: #f3f7fa url(/common/img/titarea/tit_product_sp.jpg) center top/100% no-repeat;
		margin-bottom: 19vw;
		padding: vw(94) 0 15vw 0;
		height: auto;
		display: block;
		min-height: 1px;
	}
	&__sp {
		@include media(m) {
			position: relative;
			padding: 12vw 0 0 0;
			.l-titarea__nav__togglewrap {
				position: absolute;
				width: vw(650);
				top: 0;
				left: vw(50);
				z-index: 10;
			}
		}
	}
	&__wrap {
		display: flex;
		justify-content: space-between;
		position: relative;
		align-items: center;
		@include media(m) {
			display: block;
		}
	}
	h1 {
		font-size: 4.7rem;
		font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 1.3;
		margin-bottom: 10px;
		@include media(hover) {
			max-width: 510px;
		}
		@include media(m) {
			font-size: vw(48);
			margin: 0;
			line-height: 1.3;
		}
	}
	&__breadcrumb {
		display: flex;
		font-size: 13px;
		min-width: 100%;
		flex-wrap: wrap;
		@include media(m) {
		   width: 100%;
			padding: 0 0 vw(8) 0;
			font-size: vw(24);
			justify-content: flex-start;
		}
		a {
			color: $blue;
			transition-duration: 0.3s;
			display: inline-block;
		}
		span {
			color: #858585;
		}
		&__item {
			position: relative;
			margin-right: 26px;
			&:before {
				content: '';
				display: block;
				width: 6px;
				height: 10px;
				background:url(/common/img/ico_arrownews_blue.svg) center bottom /6px 10px no-repeat;
				position: absolute;
				right: -16px;
				top: 6px;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
			@include media(m) {
				margin-right: vw(26);
				&:before {
					width: vw(10);
					height: vw(16);
					right: vw(-17);
					top: calc(50% - 0.92vw);
					background:url(/common/img/ico_arrownews_blue_sp.png) center center /vw(10) vw(16) no-repeat;
				}
				&:last-child {
					padding-right: 0;
					margin-right: 0;
				}
			}
		}
	}
	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 490px;
		/*@include media(hover) {
			&__togglewrap {
				margin-top: 15px;
			}
		}*/
		&__item {
			width: calc((100% - (40px * 1)) / 2);
			a {
				font-size: 1.4rem;
				display: block;
				position: relative;
				width: 100%;
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #d6dadd;
				&:before {
					content: '';
					display: block;
					position: absolute;
					background: $blue;
					height: 1px;
					width: 0;
					transition-duration: 0.6s;
					bottom: -1px;
					left: 0;
				}
				&:after {
					content: '';
					display: block;
					width: 6px;
					height: 10px;
					background:url(/common/img/ico_arrownews_gray.svg) center bottom /6px 10px no-repeat;
					position: absolute;
					right: 10px;
					top: calc(50% - 5px);
				}
				&.is-active {
					color: $blue;
					border-bottom: 1px solid $blue;
					&:after {
						background:url(/common/img/ico_arrownews_blue.svg) center bottom /6px 10px no-repeat;
					}
					&.-notactive {
						color: #282828;
						border-bottom: 1px solid #d6dadd;
						&:after {
							background:url(/common/img/ico_arrownews_gray.svg) center bottom /6px 10px no-repeat;
						}
						@include media(hover) {
							&:hover {
								color: $blue;
								&:before {
									width: 100%;
								}
								&:after {
									background:url(/common/img/ico_arrownews_blue.svg) center bottom /6px 10px no-repeat;
								}
							}
						}
					}
				}
				@include media(hover) {
					&:hover {
						color: $blue;
						&:before {
							width: 100%;
						}
						&:after {
							background:url(/common/img/ico_arrownews_blue.svg) center bottom /6px 10px no-repeat;
						}
					}
				}
			}
		}
		@include media(m) {
			position: relative;
			width: vw(570);
			margin: auto;
			&:before {
				content: '';
				display: block;
				width: vw(650);
				height: 1px;
				position: absolute;
				top: 0;
				left: vw(-40);
				background: #e7e7e7;
			}
			&__item {
				width: 100%;
				&:last-child {
					a {
						border-bottom: none;
					}
				}
				a {
					font-size: vw(28);
					padding: vw(28) vw(40) vw(28) 0;
					height: auto;
					line-height: 1.3;
					height: auto;
				}
			}
			&__togglewrap {
				box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.24);
				background: #fff;
				position: relative;
				z-index: 5;
				border-radius: vw(2);
				&.-spbottom {
					width: 100%;
					position: absolute;
    				transform: translate(0, 9vw);
				}
				.is-hide {
					display: none;
				}
			}
			&__toggle {
				width: 100%;
				height: vw(90);
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				color: $blue;
				i {
					width: 13px;
					height: 13px;
					display: inline-block;
					position: absolute;
					top: calc(50% - 6px);
					right: 3.46667vw;
					&:before {
						content: '';
						display: block;
						width: 13px;
						height: 1px;
						background: $blue;
						right: 0;
						top: 6px;
						position: absolute;
					}
					&:after {
						content: '';
						display: block;
						position: absolute;
						height: 13px;
						width: 1px;
						background: $blue;
						right: 6px;
						top: 0;
					}
				}
				&.is-active {
					i {
						&:after {
							display: none;
						}
					}
				}
			}
			&.-products {
				width: vw(568);
				margin: auto;
				&:before {
					display: none;
				}
				.l-titarea__nav__item {
					width: vw(269);
					margin-bottom: vw(24);
					a{
						display: flex;
						padding: 0;
						height: vw(180);
						flex-wrap: wrap;
						align-content: center;
						justify-content: center;
						align-items: center;
						line-height: 1;
						border: none;
						background: $blue;
						color: #fff;
						border-radius: vw(6);
						&:before,&:after {
							display: none;
						}
						&.-bggreen {
							background: $green;
						}
					}
				}
				.l-titarea__nav__ico {
					width: vw(90);
					height: vw(90);
					margin: 0 0 vw(8) 0;
					background: #fff;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				img {
					&.-ico01 {
						width: vw(44);
					}
					&.-ico02 {
						width: 7.5vw;
					}
					&.-ico03 {
						width: vw(45);
						transform: translate(0.3vw,0);
					}
					&.-ico04 {
						width: vw(55);
					}
					&.-ico05 {
						width: vw(41);
					}
					&.-ico06 {
						width: vw(42);
						transform: translate(0.4vw,0);
					}
				}
				p {
					font-size: vw(26);
					text-align: center;
					width: 100%;
					color: #fff;
				}
			}
		}
	}
}
.p-products {
	.l-titarea {
		background: url(/common/img/titarea/tit_product_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_product_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			background: url(/common/img/titarea/tit_product_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-strength {
	.l-titarea {
		background: url(/common/img/titarea/tit_strength_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_strength_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			background: url(/common/img/titarea/tit_strength_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-service {
	.l-titarea {
		background: url(/common/img/titarea/tit_service_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_service_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			background: url(/common/img/titarea/tit_service_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-oempb {
	.l-titarea {
		background: url(/common/img/titarea/tit_oempb_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_oempb_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(100);
			background: url(/common/img/titarea/tit_oempb_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-company {
	.l-titarea {
		background: url(/common/img/titarea/tit_company_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_company_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(100);
			background: url(/common/img/titarea/tit_company_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-policy {
	.l-titarea {
		background: url(/common/img/titarea/tit_policy_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_policy_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(100);
			background: url(/common/img/titarea/tit_policy_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-news {
	.l-titarea {
		background: url(/common/img/titarea/tit_news_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_news_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(100);
			background: url(/common/img/titarea/tit_news_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-mail {
	.l-titarea {
		background: url(/common/img/titarea/tit_mail_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_mail_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(100);
			background: url(/common/img/titarea/tit_mail_sp.jpg) center top/100% no-repeat;
		}
	}
}
.p-contact {
	.l-titarea {
		background: url(/common/img/titarea/tit_contact_pc.jpg) center center/cover no-repeat;
		@media screen and (min-width: 1501px) {
			background: url(/common/img/titarea/tit_contact_pc.jpg) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: 8.33333vw;
			background: url(/common/img/titarea/tit_contact_sp.jpg) center top/100% no-repeat;
		}
	}
}