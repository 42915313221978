.p-company {
	.-msgname {
		font-weight: 600;
		margin-top: 15px;
	}
	&__sdgstit {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 15px;
		@include media(m) {
			margin-bottom: vw(30);
			h3 {
				margin-bottom: 0!important;
			}
		}
	}
	&__sdgsico {
		display: flex;
		li {
			margin-right: 10px;
			width: 100px;
			+ li {
				
			}
		}
		@include media(m) {
			li {
				margin-right: vw(10);
				width: vw(100);
				+ li {

				}
			}
		}
	}

}