@charset "UTF-8";


// ==========================================================================
//	案件のベースとなる値の定義
// ==========================================================================
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// color
// -----------------------------------------------------------------
$blue: #4db8bd;
$orange: #f96e31;
$green: #4dbd8f;
// transition
// -----------------------------------------------------------------
$transition: .5s cubic-bezier(.55,.14,.52,.91);

// vw
// -----------------------------------------------------------------
@function vw($val) {
	@return $val / 750 * 100vw;
}

// Media Query
// -----------------------------------------------------------------
$breakpoints: (
	l : "only screen and (max-width: 1200px)",
	r : "only screen and (max-width: 1050px)",
	m : "only screen and (max-width: 768px)",
	s : "only screen and (max-width: 480px)",
	hover : "only screen and (min-width: 769px)"
);
@mixin media($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}
