.l-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	&__enbtn {
		margin-left: 40px;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			background: #282828;
			border-radius: 30px;
			font-size: 1.3rem;
			width: 120px;
			height: 30px;
			&:before {
				content: '';
				display: inline-block;
				width: 16px;
				height: 16px;
				background: url(/common/img/ico_lang.png) center bottom /100% auto no-repeat;
				margin-right: 6px;
			}
			@include media(hover) {
				&:hover {
					background: $blue;
				}
			}
		}
		@include media(m) {
			margin: vw(40) auto 0 auto;
			width: vw(650);
			a {
				width: 100%;
				height: vw(70);
				border-radius: vw(40);
				font-size: vw(32);
				&:before {
					width: vw(37);
					height: vw(37);
					background: url(/common/img/ico_lang.png) center bottom /100% auto no-repeat;
					margin-right: vw(16);
				}
			}
		}
	}
	@include media(hover) {
		&.is-scroll {
			.l-header__bg {
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
			}
			.l-header__wrap {
				padding: 0 0 15px 0;
			}
			.l-header__subnav {
				transform: translateY(-30px);
			}
			.l-header__nav__menu__list__1st a{
				height: 87px;
			}
			.l-header__logo {
				margin-top: 15px;
			}
			.l-header__logo.-notscroll {
				display: none;
			}
			.l-header__logo.-scroll {
				display: block;
			}
		}
	}
	&__wrap {
		display: flex;
		justify-content: space-between;
		padding: 0 0 30px 0;
		max-width: 1280px;
		margin: auto;
		transition-duration: 0.3s;
		@include media(m) {
			padding: 0;
			display: block;
		}
	}
	&__bg {
		background: #fff;
		transition-duration: 0.3s;
	}
	&__logo {
		margin-top: 27px;
		transition-duration: 0.3s;
		a {
			display: block;
		}
		&.-scroll {
			display: none;
		}
		@include media(r) {
			width: 150px;
		}
		@include media(m) {
			&.-scroll {
				display: none;
			}
			width: vw(247)!important;
			margin: vw(18) 0 0 vw(30);
		}
	}
	&__sphead {
		display: flex;
		justify-content: space-between;
	}
	&__btnnav {
		display: none;
		@include media(m) {
			display: flex;
			align-items: flex-start;
			&__contact {
				display: flex;
				background: #d6f2f3;
				justify-content: center;
				align-items: center;
				width: vw(114);
				height: vw(114);
				margin-right: 1px;
				img {
					width: vw(92);
					height: vw(73);
				}
			}
			&__products {
				display: flex;
				background: #def3f4;
				justify-content: center;
				align-items: center;
				width: vw(114);
				height: vw(114);
				position: relative;
				transition-duration: 0.3s;
				img {
					width: vw(82);
					height: vw(83);
					transition-duration: 0.3s;
					position: absolute;
					top: vw(20);
					left: vw(15);
					&.-on {
						opacity: 0;
					}
				}
				&.is-active {
					img {
						&.-off {
							opacity: 0;
						}
						&.-on {
							opacity: 1;
						}
					}
				}
			}
			&__trigger {
				display: block;
				width: vw(114);
				height: vw(114);
				z-index: 200;
				background: $blue;
				position: relative;
				span {
					display: block;
					width: vw(46);
					height: 2px;
					background: #fff;
					position: absolute;
					transition: 0.3s ease;
					border-radius: 3px;
					&:nth-child(1) {
						top: vw(38);
						left: vw(34);
					}
					&:nth-child(2) {
						top: vw(53);
						left: vw(34);
					}
					&:nth-child(3) {
						top: vw(68);
						left: vw(34);
					}
				}
				&.is-active {
					span {
						&:nth-child(1) {
							transform: rotate(45deg);
							top: vw(53);
						}
						&:nth-child(2) {
							display: none;
						}
						&:nth-child(3) {
							transform: rotate(-45deg);
							top: vw(53);
						}
					}
				}
			}
		}
	}
	&__nav {
		&__menu {
			&__main {
				display: flex;
				align-items: center;
			}
			@include media(m) {
				width: 100%;
				height: 100vh;
				top: vw(114);
				left: 0;
				position: absolute;
				display: none;
				&__main {
					background: #fff;
					position: relative;
					z-index: 1;
					padding: 8.5vw 0 9vw;
					display: block;
				}
				&__bg {
					content: '';
					background: rgba(0,0,0,0.8);
					position: absolute;
					display: block;
					width: 100%;
					height: 100vh;
					top: 0;
					left: 0;
				}
			}
			&__list {
				display: flex;
				max-width: 900px;
				align-items: center;
				&__wrap {
					position: relative;
				}
				&__1st {
					position: relative;
					padding-right: 30px;
					&:last-child {
						@include media(hover) {
							padding: 0;
						}
					}
					a {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 1.5rem;
						height: 116px;
						font-weight: 600;
						@include media(hover) {
							&:hover {
								color: $blue;
							}
						}
					}
					span {
						display: none;
					}
					@include media(hover) {
						&.is-active {
							> a,> span {
								color: $blue;
							}
						}
						ul {
							display: none;
						}
					}
				}
				li.-tel {
					transform: translate(0px, -3px);
				}
				@media screen and (max-width: 1140px) {
					&__1st {
						padding-right: 25px;
						a {
							font-size: 1.3rem;
						}
					}
					li.-tel {
						img {
							width: 140px
						}
					}
				}
				@media screen and (max-width: 1050px) {
					&__1st {
						padding-right: 10px;
						a {
							font-size: 1.2rem;
						}
					}
					li.-tel {
						img {
							width: 100px
						}
					}
				}
				@include media(m) {
					display: flex;
					flex-wrap: wrap;
					width: vw(650);
					margin: 0 auto vw(24) auto;
					&__1st {
						width: 100%;
						padding-right: 0;
						border-top: 1px solid #e7e7e7;
						> a:not([class]) {
							display: none;
						}
						a {
							height: auto;
						}
						a.-arrow,span {
							display: flex;
							justify-content: space-between;
							padding: 3vw vw(25);
							align-items: center;
							position: relative;
							font-size: vw(32);
							font-weight: 400;
						}
						a.-arrow {
							i {
								position: absolute;
								width: 8px;
								height: 8px;
								right: vw(33);
								top: calc(50% - 4px);
								transform: rotate(135deg);
								&:before {
									content: '';
									width: 100%;
									height: 1px;
									background: #282828;
									top: 0;
									position: absolute;
									left: 0;
									border-radius: vw(10);
								}
								&:after {
									content: '';
									width: 1px;
									height: 100%;
									background: #282828;
									top: 0;
									left: 0;
									position: absolute;
								}
							}
						}
						span {
							i {
								width: 13px;
								height: 13px;
								display: inline-block;
								position: absolute;
								top: calc(50% - 6px);
								right: 3.46667vw;
								&:before {
									content: '';
									display: block;
									width: 13px;
									height: 1px;
									background: #282828;
									right: 0;
									top: 6px;
									position: absolute;
								}
								&:after {
									content: '';
									display: block;
									position: absolute;
									height: 13px;
									width: 1px;
									background: #282828;
									right: 6px;
									top: 0;
								}
							}
							&.is-active {
								i {
									&:after {
										display: none;
									}
								}
							}
						}
						ul {
							display: none;
							padding: vw(10) vw(25) vw(30) vw(25);
							li {
								+ li {
									margin-top: vw(15);
								}
							}
							a {
								display: block;
								position: relative;
								padding-left: vw(25);
								font-size: vw(28);
								font-weight: 400;
								&:before {
									content: '';
									display: block;
									width: vw(7);
									height: 1px;
									left: 0;
									top: vw(23);
									background: #282828;
									position: absolute;
								}
							}
						}
					}
					li.-tel {
						width: 100%;
						border-top: 1px solid #e7e7e7;
						a {
							margin-top: vw(38);
							display: flex;
							justify-content: center;
							align-items: center;
							background: #f1f4f7;
							width: 100%;
							height: vw(134);
							img {
								width: vw(378);
								height: vw(88);
							}
						}
					}
				}
			}
			#slide-line {
				position: absolute;
				top: 0;
				height: 3px;
				background-color:$blue;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				@include media(m) {
					display: none;
				}
			}
			@include media(m) {
				.l-header__mail {
					width: vw(650);
					display: flex;
					justify-content: center;
					align-items: center;
					background: $blue;
					height: vw(88);
					color: #fff;
					margin: auto;
					font-size: vw(32);
					span {
						display: inline-block;
					}
					img {
						width: vw(38);
						height: vw(38);
						display: inline-block;
						margin-right: vw(23);
						transform: translate(0,-0.7vw);
					}
				}
			}
		}
		&__products {
			@include media(hover) {
				display: none!important;
			}
			display: none;
			width: 100%;
			height: 100vh;
			top: vw(114);
			left: 0;
			position: absolute;
			&__main {
				background: #fff;
				position: relative;
				z-index: 1;
				padding: 6.33333vw 0 5vw;
			}
			&__bg {
				content: '';
				background: rgba(0,0,0,0.8);
				position: absolute;
				display: block;
				width: 100%;
				height: 100vh;
				top: 0;
				left: 0;
			}
			h2 {
				width: vw(650);
				margin: 0 auto vw(35) auto;
			}
			&__list {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 490px;
				position: relative;
				@include media(m) {
					width: vw(650);
					margin: auto;
				}
				&__item {
					width: calc((100% - (40px * 1)) / 2);
					a {
						font-size: 1.4rem;
						display: block;
						position: relative;
						width: 100%;
						height: 44px;
						line-height: 44px;
						border-bottom: 1px solid #d6dadd;
						&:before {
							content: '';
							display: block;
							position: absolute;
							background: $blue;
							height: 1px;
							width: 0;
							transition-duration: 0.6s;
							bottom: -1px;
							left: 0;
						}
						&:after {
							content: '';
							display: block;
							width: 6px;
							height: 10px;
							background:url(/common/img/ico_arrownews_gray.svg) center bottom /6px 10px no-repeat;
							position: absolute;
							right: 10px;
							top: calc(50% - 5px);
						}
						&.is-active {
							color: $blue;
							border-bottom: 1px solid $blue;
							&:after {
								background:url(/common/img/ico_arrownews_blue.svg) center bottom /6px 10px no-repeat;
							}
						}
					}
				}
				@include media(m) {
					&__item {
						width: vw(314);
						margin-bottom: vw(24);
					}
					a{
						display: flex;
						padding: 0;
						height: vw(180);
						flex-wrap: wrap;
						align-content: center;
						justify-content: center;
						align-items: center;
						line-height: 1;
						border: none;
						background: $blue;
						color: #fff;
						border-radius: vw(6);
						&.-bggreen {
							background: $green;
						}
						&:before,&:after {
							display: none;
						}
					}
					&__ico {
						width: vw(90);
						height: vw(90);
						margin: 0 0 vw(8) 0;
						background: #fff;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					img {
						&.-ico01 {
							width: vw(44);
						}
						&.-ico02 {
							width: 7.5vw;
						}
						&.-ico03 {
							width: vw(45);
							transform: translate(0.3vw,0);
						}
						&.-ico04 {
							width: vw(55);
						}
						&.-ico05 {
							width: vw(41);
						}
						&.-ico06 {
							width: vw(42);
							transform: translate(0.4vw,0);
						}
					}
					p {
						font-size: vw(26);
						text-align: center;
						width: 100%;
						color: #fff;
					}
				}
			}
		}
	}
	&__subnav {
		display: flex;
		justify-content: center;
		width: 100%;
		transition-duration: 0.3s;
		transform: translate(0,-30px);
		&__inner {
			max-width: 1025px;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
		&__list {
			display: flex;
			align-items: center;
			box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			overflow: hidden;
			a,span {
				display: flex;
				padding: 0 24px;
				align-items: center;
				justify-content: center;
				font-size: 1.5rem;
				height: 52px;
			}
			span {
				color: $blue;
				background: #fff;
				position: relative;
				padding: 0 23px 0 22px;
				&:after {
					content: '';
					display: block;
					position: absolute;
					right: -8px;
					top: calc(50% - 8px);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 0 8px 8px;
					border-color: transparent transparent transparent #ffffff;
					z-index: 1;
				}
				img {
					display: inline-block;
					margin-right: 7px;
				}
			}
			li {
				position: relative;
				&:before {
					content: '';
					display: block;
					width: 1px;
					height: 20px;
					top: calc(50% - 10px);
					right: 0;
					position: absolute;
					background: #379ba0;
					
				}
				&:nth-child(2) {
					a {
						padding-left: 40px;
					}
				}
				&:last-child {
					a {
						padding-right: 30px;
					}
					&:before {
						display: none;
					}
				}
			}
			a {
				color: #fff;
				background: $blue;
				@include media(hover) {
					&:hover {
						background: #3fa7ac;
					}
				}
			}
		}
		&__btn {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 29px;
			height: 52px;
			color: #fff!important;
			border-radius: 4px;
			box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
			background: $green;
			font-size: 1.4rem;
			@include media(hover) {
				&:hover {
					background: #40a67c;
				}
			}
		}
		@include media(r) {
			&__inner {
				max-width: 780px;
			}
			&__list {
				a,span {
					padding: 0 10px;
					font-size: 1.3rem;
				}
				span {
					padding: 0 10px 0 10px;
				}
				li {
					&:nth-child(2) {
						a {
							padding-left: 20px;
						}
					}
					&:last-child {
						a {
							padding-right: 20px;
						}
						&:before {
							display: none;
						}
					}
				}
			}
			&__btn {
				padding: 0 10px;
				font-size: 1.3rem;
			}
		}
		@include media(m) {
			display: none;
		}
	}
}
.p-top {
	.l-header__subnav {
		display: none;
	}
	@include media(hover) {
		.l-header.is-scroll {
			.l-header__bg {
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
			}
			.l-header__wrap {
				padding: 0 0 0 0;
			}
			.l-header__subnav {
				transform: translateY(-20px);
			}
			.l-header__nav__menu__list__1st a{
				height: 87px;
			}
			.l-header__logo {
				margin-top: 15px;
			}
			.l-header__logo.-notscroll {
				display: none;
			}
			.l-header__logo.-scroll {
				display: block;
			}
		}
	}
}