.p-contact {
	&__cap {
		margin-bottom: 70px;
		line-height: 1.6em;
		@include media(m) {
			margin-bottom: vw(68);
		}
	}
	&__att {
		margin-bottom: 70px;
		padding: 24px 35px;
		strong {
			font-size: 20px;
			color: $blue;
			display: block;
			margin-bottom: 15px;
			font-weight: 500;
		}
		p {
			+ p {
				margin-top: 15px;
			}
		}
		@include media(m) {
			margin-bottom: vw(60);
			padding: vw(20) vw(35);
			strong {
				font-size: vw(34);
				margin-bottom: vw(10);
			}
			p {
				+ p {
					margin-top: vw(30);
				}
			}
		}
	}
	&__form {
		border-top: 1px solid #d9e3ec;
		margin-bottom: 50px;
		&.-confirm {
			th,td {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			td {
				width: 100%;
			}
			span {
				top: 30px;
				@include media(m) {
					top: 4vw;
				}
			}
		}
		tr {
			border-bottom: 1px solid #d9e3ec;
		}
		th {
			text-align: left;
			align-items: center;
			padding: 45px 33px 20px 30px;
			min-width: 290px;
			font-weight: 400;
			background: #f4f7fa;
			position: relative;
			vertical-align: top;
			&.-bggreen {
				background: #f1f9f4;
			}
			&.-spnowrap {
				padding-top: 35px;
			}
			span {
				position: absolute;
				right: 33px;
				top: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 70px;
				height: 20px;
				color: #fff;
				font-size: 1.2rem;
				line-height: 1;
				background: #f92519;
				font-weight: 300;
			}
			p {
				width: 100%;
				font-size: 14px;
				color: #8d8d8d;
			}
		}
		td {
			padding: 30px 35px 20px 35px;
			.-cap {
				margin-bottom: 10px;
			}
		}
		&__select {
			position: relative;
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				right: 23px;
				top: calc(50% - 3px);
				transition-duration: 0.3s;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 7px 0 7px;
				border-color: #ffffff transparent transparent transparent;
			}
		}
		&__email {
			+ div {
				margin-top: 15px;
			}
			p {
				margin-bottom: 10px;
			}
		}
		&__zip {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			width: 230px;
			p {
				margin-right: 20px;
			}
		}
		&__addselect {
			position: relative;
			width: 230px;
			margin-bottom: 15px;
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				right: 18px;
				top: calc(50% - 5px);
				transition-duration: 0.3s;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 7px 7px 0 7px;
				border-color: #ffffff transparent transparent transparent;
			}
		}
		&__add {
			
		}
		&__radio {
			li {
				+ li {
					margin-top: 10px;
				}
			}
		}
		&__doc {
			margin-top: 40px;
			&__main {
				border-radius: 2px;
				background: #f5f5f5;
				font-weight: 600;
				padding-left: 26px;
			}
			> ul {
				> li {
					+ li {
						margin-top: 8px;
					}
				}
				ul {
					padding: 10px 0 5px 65px;
					li {
						font-size: 1.4rem;
						+ li {
							border-top: 1px solid #d9e3ec;
						}
					}
				}
			}
		}
		.-productwrap {
			margin-top: 20px;
		}
		.-productlist {
			margin-top: 10px;
			> li {
				padding-left: 16px;
				position: relative;
				line-height: 1.3;
				margin-bottom: 6px;
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					position: absolute;
					top: 8px;
					left: 0;
					content: '';
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: #282828;
				}
			}
		}
		
		@include media(m) {
			margin-bottom: vw(65);
			border-top: none;
			&.-confirm {
				th {
					padding: vw(35) vw(30) vw(35) vw(30);
				}
				td {
					padding: vw(35) vw(30) vw(35) vw(30);
				}
			}
			tr {
				border:none;
				display: block;
				+ tr {
					margin-top: vw(24);
				}
			}
			th {
				width: 100%;
				padding: vw(35) vw(30) vw(35) vw(30);
				font-size: vw(34);
				line-height: 1em;
				display: flex;
				border-bottom: 1px solid #d9e3ec;
				&.-bggreen {
					+ td {
						background: #f1f9f4;
					}
				}
				&.-spnowrap {
					flex-wrap: nowrap;
					justify-content: flex-start;
					padding-top: vw(30);
					p {
						font-size: vw(28);
						margin-left: vw(30);
						width: auto;
					}
				}
				span {
					width: vw(129);
					height: vw(42);
					font-size: vw(24);
					top: 4vw;
					right: 5vw;
				}
			}
			td {
				background: #f4f7fa;
				display: block;
				width: 100%;
				padding: vw(30) vw(30) vw(35) vw(30);
			}
			&__select {
				width: 100%;
				&:after {
					right: vw(36);
					top: calc(50% - 1vw);
					width: vw(13);
					height: vw(23);
				}
			}
			&__email {
				+ div {
					margin-top: vw(20);
				}
				p {
					margin-bottom: vw(10);
				}
			}
			&__zip {
				margin-bottom: vw(20);
				width: vw(400);
				p {
					margin-right: vw(20);
				}
			}
			&__addselect {
				width: vw(400);
				margin-bottom: vw(20);
				&:after {
					right: vw(36);
					top: calc(50% - 1vw);
					width: vw(13);
					height: vw(23);
				}
			}
			&__radio {
				li {
					+ li {
						margin-top: 1vw;
					}
				}
			}
			&__doc {
				margin-top: vw(30);
				&__main {
					background: #fff;
					padding-left: vw(32);
				}
				> ul {
					> li {
						+ li {
							margin-top: vw(25);
						}
					}
					ul {
						padding: vw(20) 0 0 vw(80);
						li {
							font-size: vw(28);
							line-height: 1.2;
						}
					}
				}
			}
			.-productwrap {
				margin-top: vw(30);
			}
			.-productlist {
				margin-top: vw(10);
				> li {
					padding-left: vw(30);
					line-height: 1.3;
					margin-bottom: vw(12);
					&::before {
						top: vw(12);
						width: vw(12);
						height: vw(12);
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	input[type=text],input[type=tel],input[type=email],textarea {
		box-sizing: border-box;
		padding: 13px 22px;
		width: 100%;
		border: 1px solid #282828;
		font-weight: 400;
		-webkit-appearance: none;
		border-radius: 2px;
		background: #fff;
		&:focus {
			border: 1px solid $blue;
			outline: none;
			background: #f4f8fb;
		}
		&.-error {
			border: 1px solid #ea608e;
			background: #ffeeed;
		}
		&::placeholder {
			color: #8d8d8d;
		}
		&:-ms-input-placeholder {
			color: #8d8d8d!important;
		}
		@include media(m) {
			padding: vw(21) vw(20) vw(21) vw(32);
			border: 1px solid #8d8d8d;
			&:focus {
				background: #fff;
			}
		}
	}
	select {
		box-sizing: border-box;
		padding: 13px 48px 13px 22px;
		width: 100%;
		border: none;
		border: 1px solid #282828;
		font-weight: 400;
		background: #282828;
		color: #fff;
		-webkit-appearance: none;
		border-radius: 2px;
		cursor: pointer;
		@include media(m) {
			padding: vw(21) 10.26667vw vw(21) vw(32);
			border: 1px solid #8d8d8d;
		}
	}
	input[type=radio] {
		display: none;
	}
	.error_010 {
		color: #f92519;
		margin-bottom: 20px;
		font-size: 2.2rem;
		@include media(m) {
			font-size: vw(32);
		}
	}
	.-radio {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		padding: 0 0 0 35px;
		position: relative;
		width: auto;
		&:before {
			background: #fff;
			border: 1px solid #282828;
			border-radius: 50%;
			content: '';
			display: block;
			height: 21px;
			left: 0px;
			margin-top: -11px;
			position: absolute;
			top: 50%;
			width: 21px;
		}
		&:after {    
			display: block;
			position: absolute;
			top: 50%;
			left: 5px;
			width: 11px;
			height: 11px;
			margin-top: -6px;
			border-radius: 50%;
			background: #282828;
			content: '';
			opacity: 0;
		}
		@include media(m) {
			padding: 0 0 0 vw(50);
			&:before {
				height: vw(33);
				margin-top: vw(-16);
				width: vw(33);
			}
			&:after {    
				left: vw(9);
				width: vw(15);
				height: vw(15);
				margin-top: vw(-7);
			}
		}
	}
	input[type=radio]:checked + .-radio::after {
		opacity: 1;
	}
	input[type=checkbox] {
		display: none;
	}
	.-checkbox {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		padding: 14px 0 14px 42px;
		position: relative;
		width: auto;
		&:before {
			background: #fff;
			border: 1px solid #282828;
			content: '';
			display: block;
			height: 26px;
			left: 0;
			position: absolute;
			top: 14px;
			width: 26px;
			border-radius: 2px;
		}
		&:after {
			border-right: 3px solid #282828;
			border-bottom: 3px solid #282828;
			content: '';
			display: block;
			height: 13px;
			left: 8px;
			opacity: 0;
			position: absolute;
			top: 18px;
			transform: rotate(45deg);
			width: 10px;
		}
		@include media(m) {
			padding: vw(25) 0 vw(25) vw(46);
			&:before {
				height: vw(34);
				width: vw(34);
				top: vw(30);
			}
			&:after {
				border-right: 2px solid #282828;
				border-bottom: 2px solid #282828;
				left: vw(12);
				top: vw(38);
				width: vw(11);
				height: vw(16);
			}
		}
	}
	input[type=checkbox]:checked + .-checkbox::after {
		opacity: 1;
	}
	
	.error_020 {
		margin-top: 10px;
		li {
			position: relative;
			background: #f92519;
			color: #fff;
			padding: 5px 20px 5px 55px;
			border-radius: 4px;
			line-height: 1.6em;
			&:before {
				display: block;
				position: absolute;    
				top: 9px;
				left: 24px;
				width: 18px;
				height: 18px;
				background: url(/contact_assets/img/ico_error.png) center bottom/cover no-repeat;
				content: '';
			}
		}
		@include media(m) {
			margin-top: 0;
			li {
				margin-top: 2.46667vw;
				padding: vw(12) vw(36) vw(12) vw(90);
				line-height: 1.4em;
				&:before {
					top: 1.7vw;
					left: vw(34);
					width: vw(35);
					height: vw(35);
				}
			}
		}
	}
	&__policy {
		text-align: center;
		margin-bottom: 30px;
		@include media(m) {
			margin-bottom: 6vw;
			line-height: 1.3;
		}
	}
	&__btnbox {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: row-reverse;
		form {
			+ form {
				margin-right: 40px;
			}
		}
		button {
			width: 330px;
			text-align: center;
			display: flex;
			position: relative;
			padding: 20px 48px;
			align-items: center;
			background: $blue;
			transition-duration: 0.5s;
			overflow: hidden;
			line-height: 1.4em;
			border-radius: 2px;
			cursor: pointer;
			font-size: 2rem;
			  box-shadow: 0px 5px 0 #47a8ad;
			transition-duration: 0.5s;
			&.-gray {
			background: #878787;
			  box-shadow: 0px 5px 0 #696969;
			}
			span {
				position: relative;
				z-index: 1;
				color: #fff;
				transition-duration: 0.5s;
				display: block;
				padding-right: 40px;
				width: 100%;
				&:after {
					content: '';
					display: block;
					width: 22px;
					height: 7px;
					right: -18px;
					top: calc(50% - 4px);
					position: absolute;
					background: url(/common/img/btn_arrow_white.png) center bottom/cover no-repeat;
					transform: translate(-20px,0);
					transition-duration: 0.5s;
				}
			}
			+ button {
				margin-right: 40px;
			}
			@include media(hover) {
				&:hover {
					transition-duration: 0.5s;
					  transform: translate3d(0, 5px, 0);
					  box-shadow: none;
					&.-gray {
					  transform: translate3d(0, 5px, 0);
					}
				}
			}
		}
		@include media(m) {
			form {
				width: 100%;
				+ form {
					margin: vw(40) 0 0 0;
				}
			}
			button {
				width: 100%;
				font-size: vw(28);
				padding: vw(32);
				justify-content: center;
				span {
					display: inline-block;
					width: auto;
					padding-right: vw(64);
					&:after {
						width: vw(34);
						height: vw(11);
						right: vw(-40);
						top: calc(50% - 1vw);
						background: url(/common/img/btn_arrow_sp_white.png) center bottom/cover no-repeat;
					}
				}
				+ button {
					margin: vw(40) 0 0 0;
				}
			}
		}
		
	}
	a.c-link {
		margin: 0;
		line-height: 1.2;
	}
}