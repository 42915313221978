.p-news {
	&__year {
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
		&__item {
			position: relative;
			+ li {
				padding-left: 38px;
				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 8px;
					left: 20px;
					width: 1px;
					height: 10px;
					transform: rotate(
				30deg);
					background: #8d8d8d;
				}
			}
		}
		a {
			display: inline-block;
			padding-bottom: 8px;
			transition-duration: 0.3s;
			border-bottom: 1px solid #fff;
			&.is-active {
				color: $blue;
				border-bottom: 1px solid $blue;
			}
			@include media(hover) {
				&:hover {
					color: $blue;
					border-bottom: 1px solid $blue;
				}
			}
		}
		@include media(m) {
			margin-bottom: vw(80);
			width: 60vw;
			margin: auto;
			flex-wrap: wrap;
			&__item {
				margin-bottom: vw(10);
				+ li {
					padding-left: vw(52);
					&:before {
						top: vw(15);
						left: vw(24);
						height: vw(20);
					}
				}
				&:nth-child(5) {
					padding: 0;
					&:before {
						display: none;
					}
				}
			}
			a {
				font-size: vw(32);
				padding-bottom: vw(7);
				line-height: 1;
			}
		}
	}
	&__article {
		margin-bottom: 75px;
		&__titwrap {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 45px;
			.p-top__tit {
				margin-bottom: 0;
			}
			
			@include media(m) {
				margin-bottom: vw(50);
				align-items: center;
			}
		}
		&__btn {
			width: 182px;
			height: 42px;
			display: flex;
			border: 1px solid #282828;
			border-radius: 2px;
			justify-content: center;
			align-items: center;
			font-size: 1.4rem;
			span {
				position: relative;
				z-index: 1;
				transition-duration: 0.5s;
				display: inline-block;
				padding-right: 24px;
				&:after {
					content: '';
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 0;
					top: calc(50% - 5px);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto no-repeat;
					transition-duration: 0.3s;
				}
			}
			@include media(m) {
				width: vw(249);
				height: vw(88);
				span {
					padding-right: vw(34);
					&:after {
						margin-left: 0;
						top: calc(50% - vw(1));
						width: vw(10);
						height: vw(19);
					}
					
				}
			}
			@include media(hover) {
				&:hover {
					background: $blue;
					border: 1px solid $blue;
					span {
						color: #fff;
						&:after {
							background: url(/common/img/ico_arrownews_white.png) center bottom/100% auto no-repeat;
						}
					}
				}
			}
		}
		&__list {
			margin-bottom: 30px;
			border-top: 1px solid #ededed;
			&__item {
				border-bottom: 1px solid #ededed;
			}
			&__tag {
				width: 78px;
				border-radius: 3px;
				display: flex;
				height: 20px;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 1.2rem;
				line-height: 1;
				margin-right: 25px;
				&.-news {
					background: #58b5c9;
				}
				&.-recruit {
					background: #258ac7;
				}
				&.-exhibition {
					background: #55b755;
				}
			}
			&__txt {
				padding: 22px 0 22px 10px;
				position: relative;
				justify-content: space-between;
				align-items: flex-start;
				display: flex;
				p {
					width: calc(100% - 190px);
					font-size: 1.4rem;
				}
				time {
					font-family: 'Montserrat', sans-serif;
					font-size: 1.2rem;
					font-weight: 600;
					letter-spacing: 0.08em;
				    width: 78px;
					color: #666;
				}
				&.-single {
					justify-content: flex-start;
					padding-left: 0;
				}
			}
			a {
				display: block;
				width: 100%;
				position: relative;
				padding-right: 63px;
				&:after {
					content: '';
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 20px;
					top: calc(50% - 5px);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto;
					transition-duration: 0.3s;
				}
				@include media(hover) {
					&:hover {
						color: $blue;
						&:after {
							background: url(/common/img/ico_arrownews_blue.png) center bottom/100% auto;
							transition-duration: 0.3s;
						}
					}
				}
			}
		}
		@include media(m) {
			margin-bottom: vw(150);
			&__list {
				margin-bottom: vw(30);
				&__tag {
					width: vw(206);
					height: vw(42);
					font-size: vw(24);
				}
				&__txt {
					padding: vw(34) 0 vw(30) 0;
					flex-wrap: wrap;
					justify-content: flex-start;
					p {
						width: 100%;
						margin-top: vw(15);
						font-size: vw(28);
						line-height: 1.3em;
						overflow: hidden;
						max-height: vw(74);
					}
					time {
						font-size: vw(27);
						margin-right: vw(23);
						font-weight: 500;
						width: auto;
					}
				}
				a {
					padding-right: vw(60);
					&:after {
						width: vw(10);
						height: vw(18);
						position: absolute;
						right: 0;
						top: calc(50% - -4vw);
					}
				}
			}
		}
	}
}
.screen-reader-text {
	display: none;
}
.nav-links {
	display: flex;
	justify-content: center;
	span {
		display: block;
		margin: 0 11px;
	}
	a {
		display: block;
		margin: 0 11px;
		color: $blue;
		border-bottom: 1px solid $blue;
		@include media(hover) {
			&:hover {
				border-bottom: 1px solid #fff;
			}
		}	
	}
	@include media(m) {
		a,span {
			margin: 0 vw(20);
		}
	}
}