.c-tab {
	display: flex;
	justify-content: space-between;
	&__item {
		display: none;
		&.is-active {
			display: flex;
		}
		figure {
			width: 170px;
			height: 170px;
			margin-right: 30px;
			border-radius: 4px;
			overflow: hidden;
			img {
				width: 170px;
				height: 170px;
				-o-object-fit: cover;
				object-fit: cover;
				font-family: 'object-fit: cover;';
				transition-duration: 0.5s;
			}
		}
		p {
			width: calc(100% - 200px);    
			line-height: 1.8;
			transform: translateY(-7px);
		}
		strong {
			&.-tit {
				display: block;
				margin-bottom: 5px;
				font-size: 2rem;
			}
		}
	}
	@include media(m) {
		&__item {
			&.is-active {
				display: block;
			}
			figure {
				width: vw(253);
				height: vw(253);
				margin-right: vw(35);
				margin-bottom: vw(35);
				float: left;
				border-radius: vw(6);
				img {
					width: vw(253);
					height: vw(253);
				}
			}
			p {
				width: 100%;    
				line-height: 1.4;
				transform: translateY(-1vw);
			}
			strong {
				&.-tit {
					margin-bottom: 5px;
					font-size: vw(30);
				}
			}
		}
	}
}