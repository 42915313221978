@charset "utf-8";
/*!
==========================================================================

SASSにて記述しておりますので、
以下のスタイルには触れないようお願いいたします。

==========================================================================
*/
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&family=Montserrat:wght@400;500;700&family=Noto+Sans+JP:wght@400;500;700&display=swap');

@import "_mixin.scss";  // 案件のベースとなる値の定義

// ==========================================================================
// Foundation
// ==========================================================================

// フォルダ指定してしまうとアルファベット順にコンパイルしてしまい優先順位が崩れるので、Foundationのみ個別表記
@import "foundation/_ress.scss"; // リセット
@import "foundation/_base.scss"; // 案件のベースとなるスタイルの定義

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_article.scss";
@import "layout/_aside.scss";
@import "layout/_document.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_indexlist.scss";
@import "layout/_inner.scss";
@import "layout/_productsnav.scss";
@import "layout/_recipesearch.scss";
@import "layout/_recommend.scss";
@import "layout/_titarea.scss";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/_btn.scss";
@import "object/component/_btnlist.scss";
@import "object/component/_flex.scss";
@import "object/component/_formico.scss";
@import "object/component/_link.scss";
@import "object/component/_sidecv.scss";
@import "object/component/_tab.scss";
@import "object/component/_tit.scss";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_404.scss";
@import "object/project/_company.scss";
@import "object/project/_contact.scss";
@import "object/project/_news.scss";
@import "object/project/_oempb.scss";
@import "object/project/_policy.scss";
@import "object/project/_products.scss";
@import "object/project/_recipes.scss";
@import "object/project/_service.scss";
@import "object/project/_single.scss";
@import "object/project/_strength.scss";
@import "object/project/_top.scss";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_align.scss";
@import "object/utility/_margin.scss";
@import "object/utility/_padding.scss";
@import "object/utility/_responsive.scss";
@import "object/utility/_smaller.scss";
@import "object/utility/_width.scss";
