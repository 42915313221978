@charset "UTF-8";

.u-aL {
	text-align: left;
}
.u-aC {
	text-align: center;
}
.u-aR {
	text-align: right;
}
