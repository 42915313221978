@charset "utf-8";

// ==========================================================================
//	案件のベースとなるスタイルの定義
// ==========================================================================

html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
	font-family: 'Noto Sans JP', sans-serif;
    font-size: 62.5%;
    line-height: 1.625;
	font-weight: 400;
	&.no-js,&.no-svg {
		margin-top: 0!important;
	}
}
#wpadminbar {
}
body {
    font-size: 1.6rem;
    overflow-x: hidden;
    min-width: 1080px;
	position: relative;
	color: #282828;
    @include media(m) {
        min-width: 320px;
        font-size: vw(28);
    }
}
main {
	margin-top: 146px;
	@include media(m) {
		margin-top: vw(114);
	}
}
a,a:visited {
    color: inherit;
    text-decoration: none;
	transition-duration: 0.3s;
}
a span:before,a span:after {
	transition-duration: 0.3s;
}
table {
    width: 100%;
    border-collapse: collapse;
}
ul, li {
    list-style: none;
}
img {
    vertical-align: bottom;
    max-width: 100%;
    -ms-interpolation-mode: bicubic;
    pointer-events: none;
}
i,em {
    font-style: normal;
}
h1,h2,h3,h4,h5 {
    font-size: inherit;
}
.u-vM {
	vertical-align: middle!important;
}
.-changecolor path{
	fill: currentColor;
}
#products__wrapper__sp {
	display: none;
	@include media(m) {
		display: inherit;
		margin-top: 6vw;
	}
}
#products__wrapper__pc {
	display: inherit;
	@include media(m) {
		display: none;
		&.-pcsp {
			display: inherit;
		}
	}
}
@include media(hover) {
	a[href^="tel:"] {
		cursor: default;
		pointer-events: none;
	}
}
// animation
// -----------------------------------------------------------------
.js-fadein {
	opacity: 0;
	transform: translate(-600px, 0);
	&.is-show {
		opacity: 1;
		transition: opacity 1s ease-in-out;
		transform: translate(0, 0);
	}
}
.-top {
	opacity: 0;
	transform: translate(0, -54px);
	&.on {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s ease 0.3s;
	}
}
.-bottom {
	opacity: 0;
	transform: translate(0, 54px);
	&.on {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s ease 0.3s;
	}
}
.-italic {
	font-style: italic;
}
.-left {
	opacity: 0;
	transform: translate(-54px, 0);
	&.on {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s ease 0.3s;
	}
}
.-right {
	opacity: 0;
	transform: translate(54px, 0);
	&.on {
		opacity: 1;
		transform: translate(0, 0);
		transition: 0.7s ease 0.3s;
	}
}
