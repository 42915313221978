.c-sidecv {
	position: fixed;
	right: 0;
	top: calc(50% - 130px);
	z-index: 10;
	li {
		+ li {
			margin-top: 12px;
		}
	}
	a {
		display: flex;
		width: 54px;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 173px;
		background: $orange;
		border-radius: 5px 0 0 5px;
		color: #fff;
		font-size: 1.4rem;
		font-weight: 600;
		line-height: 1.1;
		@include media(hover) {
			&:hover {
				background: $blue;
			}
		}
	}
	span {
		position: relative;
		padding-top: 24px;
		display: inline-block;
		width: 14px;
		&.-contact {
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: calc(50% - 5px);
				top: 0;
				left: -3px;
				width: 20px;
				height: 17px;
				background: url(/parts/img/ico_contact.png) center bottom/100% auto;
			}
		}
		&.-me {
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: calc(50% - 5px);
				top: 0;
				left: -3px;
				width: 20px;
				height: 19px;
				background: url(/parts/img/ico_me.png) center bottom/100% auto;
			}
		}
	}
	@include media(m) {
		display: none;
	}
}
