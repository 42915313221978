.l-recommend {
	margin-bottom: 230px;
	&.-mbM {
		margin-bottom: 120px;
	}
	&__slide {
		&__item {
			padding: 0 18px;
		}
		a {
			display: block;
			@include media(hover) {
				&:hover {
					opacity: 0.7;
				}
			}
		}
		figure {
			overflow: hidden;
			width: 100%;
			height: 216px;
			border-radius: 4px;
			margin-bottom: 20px;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			img {
				width: 100%;
				height: 216px;
				-o-object-fit: cover;
				object-fit: cover;
				font-family: 'object-fit: cover;';
				transition-duration: 0.5s;
			}
		}
		&__tag {
			display: flex;
			flex-wrap: wrap;
			> span {
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 18px;
				height: 30px;
				font-size: 1.4rem;
				line-height: 1;
				margin: 0 4px 8px 0;
				i {
					display: block;
					margin-right: 7px;
					line-height: 1;
				}
				&.-drink {
					border: 1px solid #0168b7;
					color: #0168b7;
					i {
						width: 15px;
						height: 18px;
						background: url(/common/img/svgico/form/color/ico_drink.svg) center center/contain no-repeat;
						img {
							width: 15px;
						}
					}
				}
				&.-jelly {
					border: 1px solid #ddd100;
					color: #ddd100;
					i {
						width: 18px;
						height: 18px;
						background: url(/common/img/svgico/form/color/ico_jelly.svg) center center/contain no-repeat;
						img {
							width: 18px;
						}
					}
				}
				&.-tablet {
					border: 1px solid #019e97;
					color: #019e97;
					i {
						width: 19px;
						height: 17px;
						background: url(/common/img/svgico/form/color/ico_tablet.svg) center center/contain no-repeat;
						img {
							width: 19px;
						}
					}
				}
				&.-soft {
					border: 1px solid #1d2089;
					color: #1d2089;
					i {
						width: 16px;
						height: 16px;
						background: url(/common/img/svgico/form/color/ico_soft.svg) center center/contain no-repeat;
						img {
							width: 16px;
						}
					}
				}
				&.-hard {
					border: 1px solid #920784;
					color: #920784;
					i {
						width: 16px;
						height: 16px;
						background: url(/common/img/svgico/form/color/ico_hard.svg) center center/contain no-repeat;
						img {
							width: 16px;
						}
					}
				}
				&.-powder {
					border: 1px solid #ce2884;
					color: #ce2884;
					i {
						width: 22px;
						height: 15px;
						background: url(/common/img/svgico/form/color/ico_powder.svg) center center/contain no-repeat;
						img {
							width: 22px;
						}
					}
				}
				&.-bread {
					border: 1px solid #c90050;
					color: #c90050;
					i {
						width: 18px;
						height: 17px;
						background: url(/common/img/svgico/form/color/ico_bread.svg) center center/contain no-repeat;
						img {
							width: 18px;
						}
					}
				}
				&.-meat {
					border: 1px solid #e70012;
					color: #e70012;
					i {
						width: 21px;
						height: 14.81px;
						background: url(/common/img/svgico/form/color/ico_meat.svg) center center/contain no-repeat;
						img {
							width: 21px;
						}
					}
				}
				&.-sidedish {
					border: 1px solid #149d64;
					color: #149d64;
					i {
						width: 22px;
						height: 15px;
						background: url(/common/img/svgico/form/color/ico_sidedish.svg) center center/contain no-repeat;
						img {
							width: 22px;
						}
					}
				}
				&.-noodles {
					border: 1px solid #8fc320;
					color: #8fc320;
					i {
						width: 23px;
						height: 18px;
						background: url(/common/img/svgico/form/color/ico_noodles.svg) center center/contain no-repeat;
						img {
							width: 23px;
						}
					}
				}
				&.-water {
					border: 1px solid #00a0ea;
					color: #00a0ea;
					i {
						width: 15px;
						height: 21px;
						background: url(/common/img/svgico/form/color/ico_water.svg) center center/contain no-repeat;
						img {
							width: 15px;
						}
					}
				}
				&.-oil {
					border: 1px solid #f28c00;
					color: #f28c00;
					i {
						width: 15px;
						height: 21px;
						background: url(/common/img/svgico/form/color/ico_oil.svg) center center/contain no-repeat;
						img {
							width: 15px;
						}
					}
				}
			}
		}
		&__tit {
			line-height: 1.3;
		}
		.slick-dots {    
			position: absolute;
			right: 0;
			bottom: -40px;
			width: 100%;
			@include media(m) {
				right: 0;
				bottom: vw(-50);
				top: auto;
			}
			li {
				position: relative;
				display: inline-block;
				width: 12px;
				height: 12px;
				margin: 0 3px;
				padding: 0;
				cursor: pointer;
				@include media(m) {
					width: vw(18);
					height: vw(18);
					margin: 0 vw(3);
				}
				&.slick-active {
					button {
						&::before {
							opacity: 1;
							background: $blue;
						}
					}
				}
				button {
					font-size: 0;
					line-height: 0;
					display: block;
					width: 10px;
					height: 10px;
					padding: 5px;
					cursor: pointer;
					border: 0;
					outline: none;
					border: 1px solid $blue;
					background: #fff;
					border-radius: 50%;
					position: relative;
					&::before {
						line-height: 0;
						position: absolute;
						top: 0;
						left: 0;
						width: 10px;
						height: 10px;
						text-align: center;
						background: #fff;
						border-radius: 50%;
						opacity: 0;
						content: '';
					}
					@include media(m) {
						width: vw(18);
						height: vw(18);
						padding: vw(5);
						&::before {
							width: vw(16);
							height: vw(16);
							top: 0;
							left: 0;
							transform: translate(-0.1vw,-0.1vw);
						}
					}
				}
			}
		}
		.slick-track.-only {
			transform: translate3d(0,0,0)!important;
		}
		.slick-next {
			width: 49px;
			height: 49px;
			z-index: 10;
			top: 105px;
			right: calc(13% + 18px);
			border-radius: 50%;
			background: $blue;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 2px;
				height: 12px;
				border-radius: 30px;
				background: #fff;
				content: '';
				transform: rotate(45deg);
				top: 22px;
				left: 25px;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 2px;
				height: 12px;
				border-radius: 30px;
				background: #fff;
				transform: rotate(-45deg);
				top: 14px;
				left: 25px;
			}
		}
		.slick-prev {
			width: 49px;
			height: 49px;
			z-index: 10;
			top: 105px;
			left: calc(13% + 18px);
			border-radius: 50%;
			background: $blue;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 2px;
				height: 12px;
				border-radius: 30px;
				background: #fff;
				transform: rotate(45deg);
				top: 14px;
				left: 22px;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 2px;
				height: 12px;
				border-radius: 30px;
				background: #fff;
				content: '';
				transform: rotate(-45deg);
				top: 22px;
				left: 22px;
			}
		}
	}
	@include media(m) {
		margin-bottom: 21.06667vw;
		&.-mbM {
			margin-bottom: 21.06667vw;
		}
		&.-spmt {
			margin-top: 21.06667vw;
		}
		&__slide {
			&__item {
				padding: 0 vw(16);
			}
			figure {
				width: 100%;
				height:vw(304);
				border-radius: vw(4);
				margin-bottom: vw(20);
				img {
					width: 100%;
					height:vw(304);
				}
			}
			&__tag {
				> span {
					border-radius: vw(100);
					padding: 0 vw(33);
					height: vw(54);
					font-size: vw(24);
					margin: 0 vw(5) vw(8) 0;
					i {
						margin-right: vw(7);
					}
					&.-drink {
						border: 1px solid #0168b7;
						color: #0168b7;
						i {
							width: vw(28);
							height: vw(35);
							img {
								width: vw(28);
							}
						}
					}
					&.-jelly {
						border: 1px solid #ddd100;
						color: #ddd100;
						i {
							width: vw(31);
							height: vw(31);
							img {
								width: vw(31);
							}
						}
					}
					&.-tablet {
						border: 1px solid #019e97;
						color: #019e97;
						i {
							width: vw(32);
							height: vw(29);
							img {
								width: vw(32);
							}
						}
					}
					&.-soft {
						border: 1px solid #1d2089;
						color: #1d2089;
						i {
							width: vw(32);
							height: vw(32);
							img {
								width: vw(32);
							}
						}
					}
					&.-hard {
						border: 1px solid #920784;
						color: #920784;
						i {
							width: vw(32);
							height: vw(32);
							img {
								width: vw(32);
							}
						}
					}
					&.-powder {
						border: 1px solid #ce2884;
						color: #ce2884;
						i {
							width: vw(37);
							height: vw(26);
							img {
								width: vw(37);
							}
						}
					}
					&.-bread {
						border: 1px solid #c90050;
						color: #c90050;
						i {
							width: vw(34);
							height: vw(31);
							img {
								width: vw(34);
							}
						}
					}
					&.-meat {
						border: 1px solid #e70012;
						color: #e70012;
						i {
							width: vw(37);
							height: vw(28);
							img {
								width: vw(37);
							}
						}
					}
					&.-sidedish {
						border: 1px solid #149d64;
						color: #149d64;
						i {
							width: 4.73333vw;
							height: 3.2vw;
							img {
								width: 4.73333vw;
							}
						}
					}
					&.-noodles {
						border: 1px solid #8fc320;
						color: #8fc320;
						i {
							width: vw(35);
							height: vw(28);
							img {
								width: vw(35);
							}
						}
					}
					&.-water {
						border: 1px solid #00a0ea;
						color: #00a0ea;
						i {
							width: vw(21);
							height: vw(28);
							img {
								width: vw(21);
							}
						}
					}
					&.-oil {
						border: 1px solid #f28c00;
						color: #f28c00;
						i {
							width: vw(21);
							height: vw(28);
							img {
								width: vw(21);
							}
						}
					}
				}
			}
			.slick-next {
				width: vw(82);
				height: vw(82);
				z-index: 10;
				top: 20vw;
				right: vw(13);
				border-radius: 50%;
				background: url(/common/img/slidearrow_next_sp.png) center bottom/vw(82) auto no-repeat;
				&:before,&:after {
					display: none;
				}
			}
			.slick-prev {
				width: vw(82);
				height: vw(82);
				z-index: 10;
				top: 20vw;
				left: vw(13);
				border-radius: 50%;
					background: url(/common/img/slidearrow_prev_sp.png) center bottom/vw(82) auto no-repeat;
				&:before,&:after {
					display: none;
				}
			}
		}
	}
}