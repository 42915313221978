.l-aside {
	margin-top: 82px;
	@include media(m) {
		margin-bottom: 21vw;
		margin-top: 8vw;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		&:before,&:after {
			content: '';
			display: block;
		}
		&:before {
			order: 1;
		}
		li {
			margin-bottom: 15px;
		}
		&.-column04 {
			li,&:before,&:after {
				 width: calc((100% - (16px * 3)) / 4);
			}
		}
		&.-column03 {
			li,&:before,&:after {
				 width: calc((100% - (16px * 2)) / 3);
			}
		}
		a {
			display: flex;
			position: relative;
			padding: 15px 24px;
			align-items: center;
			background: #f1f1f1;
			border: 1px solid #f1f1f1;
			transition-duration: 0.5s;
			overflow: hidden;
			line-height: 1.4em;
			border-radius: 2px;
			cursor: pointer;
			span {
				position: relative;
				z-index: 1;
				color: #282828;
				transition-duration: 0.5s;
				display: block;
				padding-right: 53px;
				width: 100%;
				&:after {
					content: '';
					display: block;
					width: 22px;
					height: 7px;
					right: -18px;
					top: calc(50% - 4px);
					position: absolute;
					background: url(/common/img/btn_arrow_black.png) center bottom/cover no-repeat;
					transform: translate(-20px,0);
					transition-duration: 0.5s;
				}
			}
			@include media(hover) {
				&.is-active {
					background: $blue;
					border: 1px solid $blue;
					span {
						color: #fff;
						&:after {
							background: url(/common/img/btn_arrow_white.png) center bottom/cover no-repeat;
						}
					}
				}
				&:hover {
					background: $blue;
					border: 1px solid $blue;
					span {
						color: #fff;
						&:after {
							background: url(/common/img/btn_arrow_white.png) center bottom/cover no-repeat;
						}
					}
				}
			}
		}
		@include media(m) {
			padding: vw(2) vw(38);
			background: #f4f7fa;
			&:before,&:after {
				display: none;
			}
			li {
				margin-bottom: 0;
				a {
					border-bottom: 1px solid #e7e7e7;
				}
			}
			&.-column04 {
				li {
					width: 100%;
				}
			}
			&.-column03 {
				li {
					width: 100%;
					&:last-child {
						a {
							border-bottom: none;
						}
					}
				}
				a {
					border-bottom: 1px solid #e7e7e7;
				}
			}
			a {
				display: flex;
				position: relative;
				padding: vw(24) vw(40) vw(24) 0;
				align-items: center;
				background: none;
				transition-duration: 0.5s;
				overflow: hidden;
				line-height: 1.3;
				border-radius: 2px;
				cursor: pointer;
				border: 1px solid #f4f7fa;
				&.is-active {
					border-bottom: 1px solid $blue;
					span {
						color: $blue;
					}
					i {
						background: url(/common/img/ico_arrownews_blue.svg) center bottom/6px 10px no-repeat;
					}
				}
				i {
					display: block;
					width: 6px;
					height: 10px;
					position: absolute;
					right: vw(18);
					background: url(/common/img/ico_arrownews_gray.svg) center bottom/6px 10px no-repeat;
				}
				span {
					z-index: 1;
					display: block;
					padding-right: 0;
					color: #333;
					&:after {
						display: none;
					}
				}
			}
		}
	}
	&__bnr {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		li {
			margin-bottom: 15px;
		}
		&.-column04 {
			li,&:before,&:after {
				 width: calc((100% - (16px * 3)) / 4);
			}
		}
		&.-column03 {
			li,&:before,&:after {
				 width: calc((100% - (32px * 2)) / 3);
			}
		}
		a {
			display: block;
			overflow: hidden;
			border-radius: 2px;
			position: relative;
			span {
				position: absolute;
				color: #fff;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1;
				line-height: 1.4;
			}
			img {
				transition-duration: 0.5s;
			}
			@include media(hover) {
				&:hover {
					img {
						transition-duration: 0.5s;
						transform: scale(1.1);
					}
				}
			}
		}
		i {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 50px;
			height: 50px;
			display: block;
			z-index: 1;
			background: url(/oem-pb_assets/img/bnr_aroow.png) center bottom /50px auto no-repeat;
		}
		@include media(m) {
			padding: 0;
			&:before,&:after {
				display: none;
			}
			li {
				margin-bottom: 0;
				+ li {
					margin-top: vw(23);
				}
			}
			a {
				span {
					font-size: vw(32);
				}
			}

			i {
				display: none;
			}
			&.-column04 {
				li {
					width: 100%;
				}
			}
			&.-column03 {
				li {
					width: 100%;
				}
			}
		}
	}
}