.l-document {
	margin-bottom: 115px;
	@include media(m) {
		margin-bottom: 21.06667vw;
	}
	.l-inner {
		border: 1px solid $orange;
	}
	&__tit {
		padding: 20px 20px 25px 20px;
		text-align: center;
		background: #fff2ec;
		h2 {
			color: $orange;
			font-size: 2.8rem;
			font-weight: 400;
			letter-spacing: 0.1em;
		}
		p {
			font-weight: 500;
		}
		@include media(m) {
			padding: vw(28) 0 vw(38) 0;
			h2 {
				font-size: vw(38);
			}
			p {
				line-height: 1.3;
			}
		}
	}
	&__main {
		padding: 45px 41px 38px 41px;
		h3 {
			font-size: 1.8rem;
			padding: 4px 0 4px 20px;
			border-left: 1px solid $orange;
			margin-bottom: 18px;
			font-weight: 400;
		}
		ul {
			margin-bottom: 53px;
			li {
				padding-left: 16px;
				position: relative;
				line-height: 1.3;
				margin-bottom: 8px;
				font-size: 1.4rem;
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					position: absolute;
					top: 8px;
					left: 0;
					content: '';
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: #282828;
				}
			}
		}
		@include media(m) {
			padding: vw(38) vw(35) vw(35) vw(35);
			h3 {
				font-size: vw(30);
				padding: vw(6) 0 vw(6) vw(30);
				margin-bottom: vw(30);
			}
			ul {
				margin-bottom: 11.66667vw;
				li {
					padding-left: vw(32);
					margin-bottom: vw(14);
					font-size: vw(28);
					&:last-child {
						margin-bottom: 0;
					}
					&::before {
						top: 2vw;
						width: vw(10);
						height: vw(10);
					}
				}
			}
		}
	}
	&__bnr {
		a,button {
			display: block;
			position: relative;
			box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.21);
			@include media(hover) {
				&:hover {
					opacity: 0.7;
				}
			}
		}
		img {
			display: block;
			&.l-document__bnr__balloon {
				position: absolute;
				top: 35px;
				left: 102px;
			}
		}
		@include media(m) {
			img {
				&.l-document__bnr__balloon {
					position: absolute;
					top: vw(64);
					left: vw(15);
					width: vw(224);
					htight: vw(137);
				}
			}
		}
	}
}