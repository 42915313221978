.l-footer {
	margin-top: 160px;
	@include media(m) {
		margin-top: vw(128);
	}
	&__cv {
		color: #fff;
		height: 419px;
		background: url(/common/img/cv_bg_01_pc.jpg) repeat-x;
		@include media(m) {
			height: vw(824);
			background: url(/common/img/cv_bg_01_sp.jpg) center bottom /100% 100% no-repeat;
		}
		&__wrap {
			background: url(/common/img/cv_bg_02_pc.png) center bottom /100% 100% no-repeat;
			padding: 70px 0 64px 0;
			height: 302px;
			@include media(m) {
				height: vw(684);
				padding: vw(90) 0 vw(50) 0;
				background: url(/common/img/cv_bg_02_sp.png) center bottom /100% 100% no-repeat;
			}
		}
		&__txt {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 25px;
			strong {
				line-height: 1;
				display: block;
				font-weight: 400;
				letter-spacing: 0.1em;
				font-size: 4.3rem;
				font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
			}
			p {
				font-size: 1.7rem;
				letter-spacing: 0.07em;
				white-space: nowrap;
				font-weight: 400;
				span {
					display: block;
					font-size: 1.2rem;
				}
			}
			@include media(m) {
				flex-wrap: wrap;
				margin-bottom: vw(30);
				text-align: center;
				strong {
					text-align: center;
					line-height: 1;
					display: block;
					margin-bottom: vw(30);
					font-size: vw(60);
					width: 100%;
				}
				p {
					font-size: vw(28);
					line-height: 1.3;
					span {
						margin-top: vw(10);
						font-size: vw(22);
					}
				}
			}
		}
		&__btn {
			display: flex;
			justify-content: space-between;
			li {
				width: calc((100% - (30px * 1)) / 2);
			}
			@include media(m) {
				flex-wrap: wrap;
				li {
					width: 100%;
					+ li {
						margin-top: vw(22);
					}
				}
			}
			&__tel {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #fff;
				min-height: 98px;
				border-radius: 2px;
				font-size: 2.3rem;
				font-weight: 600;
				@media all and (-ms-high-contrast: none) {
					padding-top: 20px;
				}
				.-wrap {
					display: inline-block;
					padding-left: 62px;
					position: relative;
					&:before {
						content: '';
						display: block;
						position: absolute;
						width: 37px;
						height: 42px;
						top: -2px;
						left: 0;
						background: url(/common/img/cv_tel.png) center center/37px no-repeat;
					}
				}
				.-main {
					display: inline-block;
					font-size: 1px;
					line-height: 1.4;
					width: 240px;
					margin-bottom: 3px;
				}
				.-att {
					font-size: 1.3rem;
					display: block;
					font-weight: 400;
				}
				img {
					display: none;
				}
				span img {
					display: inline-block;
				}
			}
			&__contact {
				display: flex;
				align-items: center;
				justify-content: center;
				background: $orange;
				border: 1px solid $orange;
				min-height: 98px;
				border-radius: 2px;
				font-size: 2.3rem;
				font-weight: 600;
				@media all and (-ms-high-contrast: none) {
					padding-top: 25px;
				}
				span {
					display: inline-block;
					padding-left: 66px;
					position: relative;
					&:before {
						content: '';
						display: block;
						position: absolute;
						width: 43px;
						height: 31px;
						top: 3px;
						left: 0;
						background: url(/common/img/cv_contact.png) center center/43px no-repeat;
					}
				}
				img {
					display: none;
				}
				@include media(hover) {
					&:hover {
						background: #fff;
						color: $orange;
						span {
							&:before {
								background: url(/common/img/cv_contact_orange.png) center center/43px no-repeat;
							}
						}
					}
				}
			}
			@include media(m) {
				a {
					padding: 0;
					border: none;
					background: none;
					height: auto!important;
					min-height: 0;
					img {
						display: block;
						width: 100%;
					}
					span {
						display: none!important;
					}
				}
			}
		}
	}
	&__nav {
		width: 695px;
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
		li,a {
			display: block;
			position: relative;
		}
		@include media(hover) {
			a {
				&:hover {
					color: $blue;
				}
			}
		}
		&__wrap {
			display: flex;
			justify-content: space-between;
			padding: 80px 0 80px 0;
		}
		&__1st {
			font-size: 1.4rem;
			margin-bottom: 20px;
			> a {
				font-weight: 600;
				margin-bottom: 10px;
			}
		}
		&__2nd {
			padding-left: 9px;
			font-size: 1.3rem;
			font-weight: 500;
			margin-bottom: 2px;
			&:before {
				display: block;
				position: absolute;
				top: 10px;
				left: 0;
				width: 4px;
				height: 1px;
				background: #282828;
				content: '';
			}
			a {
				
			}
		}
		@include media(m) {
			width: 100%;
			display: block;
			margin-bottom: vw(110);
			margin-top: 0;
			&__wrap {
				flex-wrap: wrap;
				padding: vw(100) 0 vw(50) 0;
			}
			li {
				&.-sporder00 {
					order: -1;
				}
				&.-sporder01 {
					order: 0;
				}
				&.-sporder02 {
					order: 1;
				}
				&.-nowrap {
					white-space: nowrap;
				}
			}
			ul {
				&.-spbggray {
					background: #f1f4f7;
					padding: vw(30) vw(35) vw(15) vw(35);
					display: flex;
					flex-wrap: wrap;
					margin-top: vw(60);
					li.l-footer__nav__1st {
						font-size:vw(26);
						margin-bottom: vw(10);
						display: inline-block;
						width: vw(285);
						> a {
							margin-bottom: vw(10);
							color: #282828;
							width: auto;
							white-space: nowrap;
							&.-spml {
								margin-left: vw(30);
							}
						}
					}
				}
			}
			&__1st {
				font-size:vw(32);
				margin-bottom: vw(40);
				> a {
					font-weight: 400;
					margin-bottom: vw(10);
					color: $blue;
				}
				ul {
					display: flex;
					flex-wrap: wrap;
				}
			}
			&__2nd {
				width: 50%;
				padding-left: 10px;
				font-size:vw(26);
				margin-bottom: vw(10);
				&:before {
					top: 50%;
					width: 4px;
				}
				a {
					
				}
			}
		}
	}
	&__info {
		width: 230px;
		font-size: 1.3rem;
		&__logo {
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 50px;
			font-weight: 400;
			span {
				font-weight: 500;
				display: block;
				margin-bottom: 5px;
			}
		}
		small {
			font-size: 1.2rem;
			color: #919191;
		}
		@include media(m) {
			width: 100%;
			font-size: vw(28);
			order: 2;
			&__logo {
				margin: 0 auto vw(4) auto;
				width: vw(330);
			}
			p {
				width: vw(495);
				margin: 0 auto vw(70) auto;
				line-height: 1.3;
			}
			small {
				font-size: vw(20);
				width: 100%;
				display: block;
				text-align: center;
			}
		}
	}
	&__pagetop {
		position: fixed;
		bottom: 0;
		right: 0;
		display: none;
		cursor: pointer;
		z-index: 10;
		a {
			display: block;
		}
		i {
			display: block;
			width: 108px;
			height: 108px;
			background: url(/common/img/pagetop_arrow_pc_on.png) center bottom/100% auto;
		}
		img {
			transition-duration: 0.5s;
		}
		@include media(hover) {
			&:hover {
				img {
					opacity: 0;
				}
			}
		}
		@include media(m) {
			i {
				background: none;
				width: auto;
				height: auto;
			}
			img {
				width: vw(146);
			}
		}
	}
}