.p-products {	
	&__select {
		position: relative;
		margin-bottom: 45px;
		z-index: 0;
		.c-btnlist {
			margin-bottom: 40px;
		}
		@include media(m) {
			margin-bottom: vw(107);
			.c-btnlist {
				margin-bottom: vw(30);
			}
		}
	}
	&__hit {
		width: calc(100% - 80px);
		margin: 0 auto 70px auto;
		background: #f4f7fa;
		padding: 45px 0 60px;
		&.-recipes {
			width: 100%;
			margin: auto;
			background: none;
			padding: 0;
		}
		@include media(m) {
			width: 100%;
			padding: 5.33333vw 0 vw(10) 0;
			margin-bottom: vw(107);
			.l-inner {
				max-width: vw(700);
			}
			.-spwrap {
				position: absolute;
				bottom: 16vw;
			}
		}
		&__wrap {
			position: relative;
		}
		&__num {
			font-weight: 500;
			margin-bottom: 6px;
			span {
				font-size: 3rem;
				display: inline-block;
				margin: 0 3px;
			}
			@include media(m) {
				margin-bottom: vw(3);
				span {
					font-size: vw(54);
				}
			}
		}
		&__form {
			padding: 15px 32px;
			background: #d8e1ea;
			position: relative;
			ul {
				display: flex;
				flex-wrap: wrap;
				width: calc(100% - 300px);
				li {
					margin: 0 22px 5px 0;
				}
				div {
					display: flex;
					font-size: 1.3rem;
					color: #3c3c3c;
					align-items: center;
					width: 133px;
				}
				i {
					margin-right: 8px;
				}
			}
			@include media(hover) {
				min-height: 100px;
			}
			@include media(m) {
				padding: vw(25) vw(25) vw(140) vw(25);
				ul {
					width: 100%;
					li {
						margin: 0 0 vw(10) 0;
						width: 33%;
					}
					div {
						line-height: 1;
						font-size: vw(24);
						width: 100%;
					}
					i {
						margin-right: vw(8);
					}
				}
			}
		}
		&__formsearch {
			display: none;
			box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.3);
			background: #fff;
			padding: 40px;
			clear: both;
			position: relative;
			z-index: 10;
			.c-btn {
				margin: auto;
				width: 312px;
				justify-content: center;
				span {
					display: inline-block;
					width: auto;
				}
				i {
					transform: rotate(45deg);
				}
			}
			@include media(m) {
				padding: vw(30) vw(40) vw(45) vw(40);
				.c-btn {
					width: vw(400);
					margin: auto;
				}
			}
			&__wrap {
				position: absolute;
				top: calc(50% - 26px);
				width: calc(100% - 66px);
				left: 33px;
				@include media(m) {
					top: 0vw;
					left: vw(25);
					width: vw(650);
				}
			}
			&__btn {
				width: 280px;
				float: right;
				&.c-btn {
					padding: 15px 30px;
				}
				@include media(m) {
					width: 100%;
					float: none;
					&.c-btn {
						padding: vw(26);
						span {
							padding: 0;
							display: block;
							width: 100%;
						}
					}
				}
			}
			&__list{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 20px;
				&::before,
				&::after {
					content: '';
					display: block;
					width: calc((100% - (16px * 3)) / 4);
				}
				&::before {
					order: 1;
				}
				li {
					width: calc((100% - (16px * 3)) / 4);
					margin-bottom: 14px;
				}
				input[type="checkbox"] {
					opacity: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					cursor: pointer;
					left: 0;
					top: 0;
				}
				
				label,a {
					background: #f1f1f1;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 0 25px;
					height: 49px;
					color: #282828;
					border-radius: 50px;
					cursor: pointer;
					position: relative;
					&.is-active {
						background: $blue;
						color: #fff;
						img {
							opacity: 0;
						}
						&:after {
							content: '';
							display: block;
							width: 13px;
							height: 8px;
							border-bottom: 2px solid #fff;
							border-left: 2px solid #fff;
							transform: rotate(-41deg);
							position: absolute;
							right: 25px;
							top: calc(50% - 6px);
						}
					}
					@include media(hover) {
						&:hover {
							background: $blue;
							color: #fff;
							img {
								opacity: 0;
							}
						}
					}
				}
				i {
					display: block;
					margin-right: 11px;
					img {
						display: block;
					}
					&.search-drink {
						width: 16px;
						height: 23px;
						background: url(/common/img/svgico/form/white/ico_drink.svg) center center/16px no-repeat;
						img {
							width: 16px;
						}
					}
					&.search-jelly {
						width: 18px;
						height: 18px;
						background: url(/common/img/svgico/form/white/ico_jelly.svg) center center/cover no-repeat;
						img {
							width: 18px;
						}
					}
					&.search-tablet {
						width: 21px;
						height: 19px;
						background: url(/common/img/svgico/form/white/ico_tablet.svg) center center/cover no-repeat;
						img {
							width: 21px;
						}
					}
					&.search-soft {
						width: 16px;
						height: 16px;
						background: url(/common/img/svgico/form/white/ico_soft.svg) center center/cover no-repeat;
						img {
							width: 16px;
						}
					}
					&.search-hard {
						width: 16px;
						height: 16px;
						background: url(/common/img/svgico/form/white/ico_hard.svg) center center/cover no-repeat;
						img {
							width: 16px;
						}
					}
					&.search-powder {
						width: 22px;
						height: 15px;
						background: url(/common/img/svgico/form/white/ico_powder.svg) center center/cover no-repeat;
						img {
							width: 22px;
						}
					}
					&.search-bread {
						width: 18px;
						height: 17px;
						background: url(/common/img/svgico/form/white/ico_bread.svg) center center/cover no-repeat;
						img {
							width: 18px;
						}
					}
					&.search-meat {
						width: 25px;
						height: 19px;
						background: url(/common/img/svgico/form/white/ico_meat.svg) center center/25px no-repeat;
						img {
							width: 25px;
						}
					}
					&.search-sidedish {
						width: 28px;
						height: 19px;
						background: url(/common/img/svgico/form/white/ico_sidedish.svg) center center/cover no-repeat;
						img {
							width: 40px;
						}
					}
					&.search-noodles {
						width: 23px;
						height: 18px;
						background: url(/common/img/svgico/form/white/ico_noodles.svg) center center/cover no-repeat;
						img {
							width: 23px;
						}
					}
					&.search-oil {
						width: 15px;
						height: 21px;
						background: url(/common/img/svgico/form/white/ico_oil.svg) center center/cover no-repeat;
						img {
							width: 15px;
						}
					}
					&.search-water {
						width: 15px;
						height: 21px;
						background: url(/common/img/svgico/form/white/ico_water.svg) center center/cover no-repeat;
						img {
							width: 15px;
						}
					}
				}
				img {
					display: inline-block;
					transition-duration: 0.3s;
					vertical-align: baseline;
				}
				@include media(m) {
					&.-recipessingle {
						width: 78vw;
						margin: auto;
						li {
							width: 38.33333vw!important;
							a {
								padding: 2.4vw 4.66667vw!important;
							}
						}
					}
					&.-apprication {
						li {
							width: 41.33333vw;
							label,a {
								justify-content: flex-start;
							}
						}
					}
					li {
						width: vw(280);
						margin-bottom: vw(20);
					}
					label,a {
						border-radius: vw(2);
						padding: vw(18) 4vw;
						height: vw(93);
						font-size: 3vw;
						justify-content: flex-start;
						white-space: nowrap;
						border-radius: vw(100);
						line-height: 1;
						&.is-active {
							&:after {
								width: 3vw;
								height: 2vw;
								right: 5vw;
								top: calc(50% - 1.5vw);
							}
						}
					}
					i {
						height: auto!important;
						width: auto!important;
						display: flex;
						align-items: center;
						margin-right: 1.33333vw;
						&.search-drink {
							background: url(/common/img/svgico/form/white/ico_drink.svg) center center/100% no-repeat;
							img {
								width: vw(29);
							}
						}
						&.search-jelly {
							background: url(/common/img/svgico/form/white/ico_jelly.svg) center center/100% no-repeat;
							img {
								width: vw(36);
							}
						}
						&.search-tablet {
							background: url(/common/img/svgico/form/white/ico_tablet.svg) center center/100% no-repeat;
							img {
								width: vw(33);
							}
						}
						&.search-soft {
							background: url(/common/img/svgico/form/white/ico_soft.svg) center center/100% no-repeat;
							img {
								width: vw(34);
								transform: translate(0, 0.3vw);
							}
						}
						&.search-hard {
							background: url(/common/img/svgico/form/white/ico_hard.svg) center center/100% no-repeat;
							img {
								width: vw(34);
								transform: translate(0, 0.3vw);
							}
						}
						&.search-powder {
							background: url(/common/img/svgico/form/white/ico_powder.svg) center center/100% no-repeat;
							img {
								width: vw(40);
							}
						}
						&.search-bread {
							background: url(/common/img/svgico/form/white/ico_bread.svg) center center/100% no-repeat;
							img {
								width: vw(35);
							}
						}
						&.search-meat {
							background: url(/common/img/svgico/form/white/ico_meat.svg) center center/100% no-repeat;
							img {
								width: vw(39);
								transform: translate(0,0);
							}
						}
						&.search-sidedish {
							background: url(/common/img/svgico/form/white/ico_sidedish.svg) center center/100% no-repeat;
							img {
								width: vw(42);
							}
						}
						&.search-noodles {
							background: url(/common/img/svgico/form/white/ico_noodles.svg) center center/100% no-repeat;
							img {
								width: vw(41);
							}
						}
						&.search-oil {
							background: url(/common/img/svgico/form/white/ico_oil.svg) center center/100% no-repeat;
							img {
								width: vw(21);
							}
						}
						&.search-water {
							background: url(/common/img/svgico/form/white/ico_water.svg) center center/100% no-repeat;
							img {
								width: vw(21);
							}
						}
					}
				}
			}
		}
		&__table {
			background: #fff;
			padding: 20px 33px 45px 33px;
			&.-recipes {
				padding: 0;
			}
			&__recipes {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 100px;
				> * {
					 width: calc((100% - (40px * 2)) / 3);
					margin-bottom: 40px;
				}
				&:before,&:after {
					content: '';
					display: block;
					width: calc((100% - (40px * 2)) / 3);
				}
				&:before {
					order: 1;
				}
				figure {
					width: 100%!important;
					height: 240px!important;
					overflow: hidden;
					margin-bottom: 10px;
					img {
						-o-object-fit: cover;
						width: 100%!important;
						height: 240px!important;
						object-fit: cover;
						font-family: 'object-fit: cover;';
						transition-duration: 0.5s;
					}
				}
				figcaption {
					text-align: center;
					line-height: 1.2em;
				}
				a {
					@include media(hover) {
						&:hover {
							figure {
								img {
									transform: scale(1.1, 1.1);
								}
							}
						}
					}
				}
			}
			table {
				table-layout: fixed;
				td,th {
					&.-w250 {
						width: 250px;
					}
					&.-w140 {
						width: 140px;
					}
					&.-w150 {
						width: 150px;
					}
				}
				ul {
					display: flex;
					flex-wrap: wrap;
					margin-top: 10px;
				}
				p {
					margin-top: 10px;
				}
				div {
					display: flex;
					justify-content: space-between;
					p {
						width: calc(100% - 130px);
						text-decoration: underline;
					}
				}
			}
			thead {
				th {
					font-weight: 600;
					border-bottom: 3px solid #ececec;
					padding: 20px;
				}
			}
			tbody {
				font-size: 1.4rem;
				th,td {
					border-bottom: 1px solid #ececec;
					position: relative;
					padding: 16px 24px;
					vertical-align: top;
					&:after {
						content: '';
						display: block;
						width: 1px;
						height: calc(100% - 32px);
						left: 0;
						top: 16px;
						background: #ececec;
						position: absolute;
					}
					&:first-child {
						padding-left: 0;
						&:after {
							display: none;
						}
					}
					&:last-child {
						padding-right: 0;
					}
				}
				tr {
					&:first-child {
						th,td {
							padding-top: 24px;
							&:after {
								top: 24px;
							}
						}
					}
					&:last-child {
						th,td {
							border-bottom: none;
						}
					}
				}
				
			}
			figure {
				width: 102px;
				height: 102px;
				border-radius: 4px;
				overflow: hidden;
				img {
					width: 102px;
					height: 102px;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					transition-duration: 0.5s;
				}
			}
			.-slush {
				display: inline-block;
				padding: 0 2px;
				&:last-child {
					display: none;
				}
			}
			@include media(m) {
				background: none;
				padding: 0;
				&__recipes {
					> * {
						width: 100%;
						margin-bottom: vw(60);
					}
					figure {
							height: 44vw!important;
						img {
							height: 44vw!important;
						}
					}
					figcaption {
						margin-top: 1.33333vw;
						font-size: vw(32);
						line-height: 1.4em;
					}
				}
				> div {
					background: #fff;
					padding: vw(38) vw(25) vw(30) vw(25);
					margin-bottom: vw(34);
				}
				table {
					ul {
						margin-top: 0;
					}
					p {
						margin-top: 0;
					}
					table-layout: auto;
					div {
						p {
							width: auto;
						}
					}
				}
				tbody {
					font-size: vw(28);
					th,td {
						padding: vw(15) 0;
						border-bottom: 1px solid #ececec;
						&:after {
							display: none;
						}
						&:first-child {
							padding-left: 0;
							&:after {
								display: none;
							}
						}
					}
					th {
						width: vw(126);
						text-align: left;
						font-size: vw(24);
					}
					tr {
						&:first-child {
							th,td {
								padding-top: 0;
							}
						}
					}
				}
				figure {
					width: vw(365);
					height: vw(250);
					margin: auto;
					border-radius: vw(6);
					img {
						width: vw(365);
						height: vw(250);
					}
				}
			}
		}
		
		@include media(hover) {
			.table-drink,.table-jelly,.table-tablet,.table-soft,.table-hard,.table-powder,.table-bread,.table-sidedish,.table-meat,.table-noodles,.table-water,.table-oil {
				display: none;
			}
			.js-def .table-drink,.js-def .table-jelly,.js-def .table-tablet,.js-def .table-soft,.js-def .table-hard,.js-def .table-powder,.js-def .table-bread,.js-def .table-meat,.js-def .table-sidedish,.js-def .table-noodles,.js-def .table-water,.js-def .table-oil {
				display: table-row!important;
			}
			.js-drink .table-drink {
				display: table-row;
			}
			.js-jelly .table-jelly {
				display: table-row;
			}
			.js-tablet .table-tablet {
				display: table-row;
			}
			.js-soft .table-soft {
				display: table-row;
			}
			.js-hard .table-hard {
				display: table-row;
			}
			.js-powder .table-powder {
				display: table-row;
			}
			.js-bread .table-bread {
				display: table-row;
			}
			.js-meat .table-meat {
				display: table-row;
			}
			.js-sidedish .table-sidedish {
				display: table-row;
			}
			.js-noodles .table-noodles {
				display: table-row;
			}
			.js-water .table-water {
				display: table-row;
			}
			.js-oil .table-oil {
				display: table-row;
			}
		}
		@include media(m) {
			.table-drink,.table-jelly,.table-tablet,.table-soft,.table-hard,.table-powder,.table-bread,.table-meat,.table-sidedish,.table-noodles,.table-water,.table-oil {
				display: none;
			}
			.js-def .table-drink,.js-def .table-jelly,.js-def .table-tablet,.js-def .table-soft,.js-def .table-hard,.js-def .table-powder,.js-def .table-bread,.js-def .table-meat,.js-def .table-sidedish,.js-def .table-noodles,.js-def .table-water,.js-def .table-oil {
				display: block!important;
			}
			.js-drink .table-drink {
				display: block;
			}
			.js-jelly .table-jelly {
				display: block;
			}
			.js-tablet .table-tablet {
				display: block;
			}
			.js-soft .table-soft {
				display: block;
			}
			.js-hard .table-hard {
				display: block;
			}
			.js-powder .table-powder {
				display: block;
			}
			.js-bread .table-bread {
				display: block;
			}
			.js-meat .table-meat {
				display: block;
			}
			.js-sidedish .table-sidedish {
				display: block;
			}
			.js-noodles .table-noodles {
				display: block;
			}
			.js-water .table-water {
				display: block;
			}
			.js-oil .table-oil {
				display: block;
			}
		}
	}
	.l-footer {
		margin-top: 180px;
		@include media(m) {
			margin-top: vw(148);
		}
	}
}