.l-recipesearch {
		margin-bottom: 135px;
		background: url(/parts/img/recipe_bg_pc.png) center center/contain no-repeat;
		padding: 80px 0 80px 0;
		.c-tit__mincho {
			margin-bottom: 20px;
		}
		@media screen and (min-width: 1501px) {
			background: url(/parts/img/recipe_bg_pc.png) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(135);
			background: url(/parts/img/recipe_bg_sp.png) center center/cover no-repeat;
			padding: vw(85) 0 vw(103) 0;
			.c-tit__mincho {
				margin-bottom: vw(20);
			}
		}
		p {
			margin-bottom: 45px;
			@include media(m) {
				margin-bottom: vw(36);
				line-height: 1.3;
			}
		}
		&__list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li {
				width: calc((100% - (16px * 4)) / 5);
				margin-bottom: 14px;
			}
			a {
				background: $blue;
				border-radius: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 15px;
				height: 58px;
				color: #fff;
				border: 1px solid $blue;
				@include media(hover) {
					&:hover {
						background: #fff;
						color: $blue;
						img {
							opacity: 0;
						}
					}
				}
			}
			span {
				display: block;
				width: 120px;
				text-align: center;
			}
			i {
				display: inline-block;
				margin-right: 5px;
				&.-ico01 {
					width: 19px;
					height: 25px;
					background: url(/common/img/svgico/form/blue/ico_drink.svg) center center/cover no-repeat;
				}
				&.-ico02 {
					width: 22px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_jelly.svg) center center/cover no-repeat;
				}
				&.-ico03 {
					width: 24px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_tablet.svg) center center/cover no-repeat;
				}
				&.-ico04 {
					width: 23px;
					height: 23px;
					background: url(/common/img/svgico/form/blue/ico_soft.svg) center center/cover no-repeat;
				}
				&.-ico05 {
					width: 24px;
					height: 24px;
					background: url(/common/img/svgico/form/blue/ico_hard.svg) center center/cover no-repeat;
				}
				&.-ico06 {
					width: 27px;
					height: 19px;
					background: url(/common/img/svgico/form/blue/ico_powder.svg) center center/cover no-repeat;
				}
				&.-ico07 {
					width: 22px;
					height: 20px;
					background: url(/common/img/svgico/form/blue/ico_bread.svg) center center/cover no-repeat;
				}
				&.-ico08 {
					width: 23px;
					height: 16px;
					background: url(/common/img/svgico/form/blue/ico_meat.svg) center center/cover no-repeat;
				}
				&.-ico09 {
					width: 28px;
					height: 19px;
					background: url(/common/img/svgico/form/blue/ico_sidedish.svg) center center/cover no-repeat;
				}
				&.-ico10 {
					width: 28px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_noodles.svg) center center/cover no-repeat;
				}
			}
			img {
				display: inline-block;
				transition-duration: 0.3s;
				vertical-align: baseline;
				&.-ico01 {
					width: 19px;
				}
				&.-ico02 {
					width: 22px;
				}
				&.-ico03 {
					width: 24px;
				}
				&.-ico04 {
					width: 23px;
				}
				&.-ico05 {
					width: 24px;
				}
				&.-ico06 {
					width: 27px;
				}
				&.-ico07 {
					width: 22px;
				}
				&.-ico08 {
					width: 23px;
					transform: translate(0, -4px);
				}
				&.-ico09 {
					width: 28px;
				}
				&.-ico10 {
					width: 28px;
				}
			}
			@include media(m) {
				li {
					width: vw(315);
					margin-bottom: vw(20);
				}
				a {
					border-radius: vw(2);
					padding: vw(18) vw(33);
					height: vw(93);
					font-size: vw(26);
					justify-content: flex-start;
					white-space: nowrap;
				}
				i {
					background: none!important;
					height: auto!important;
					width: auto!important;
					display: flex;
					align-items: center;
					margin-right: vw(25);
				}
				span {
					width: auto;
					display: inline;
				}
				img {
					&.-ico01 {
						width: vw(29);
					}
					&.-ico02 {
						width: vw(36);
					}
					&.-ico03 {
						width: vw(33);
					}
					&.-ico04 {
						width: vw(34);
						transform: translate(0, 0.3vw);
					}
					&.-ico05 {
						width: vw(34);
						transform: translate(0, 0.3vw);
					}
					&.-ico06 {
						width: vw(40);
					}
					&.-ico07 {
						width: vw(35);
					}
					&.-ico08 {
						width: vw(39);
						transform: translate(0,0);
					}
					&.-ico09 {
						width: vw(42);
					}
					&.-ico10 {
						width: vw(41);
					}
				}
			}
		}
	}