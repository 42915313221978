.p-oempb {
	&__about {
		margin-bottom: 115px;
		@include media(m) {
			margin-bottom: vw(110);
		}
	}
	&__step {
		margin-bottom: 102px;
		@include media(m) {
			padding: vw(96) 0 0 0;
			background: #eff2f2;
			margin-bottom: vw(125);
		}
		&__wrap {
			background: #eff2f2;
			padding: 72px 0 82px;
			@include media(m) {
				padding: 0 0 vw(86);
			}
		}
		&__link {
			max-width: 1024px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			display: flex;
			&__wrap {
				position: relative;
				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 70px;
					background: #eff2f2;
					bottom: 0;
					left: 0;
					position: absolute;
				}
			}
			li {
				width: 20%;
				a {
					position: relative;
					height: 142px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					padding-left: 20px;
					align-content: center;
					> span {
						position: absolute;
						top: -12px;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						span {
							width: 100px;
							height: 30px;
							padding: 0;
							display: flex;
							justify-content: center;
							align-items: center;
							line-height: 1;
							border-radius: 40px;
							color: $blue;
							font-size: 1.4rem;
							background: #fff;
							box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
							font-family: 'Poppins', sans-serif;
						}
					}
					strong {
						width: 100%;
						text-align: center;
						color: #fff;
						font-weight: 400;    
						line-height: 1.4;
						margin-top: 19px;
						height: 43px;
						margin-bottom: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					i {
						display: block;
						width: 27px;
						height: 27px;
						background: url(/oem-pb_assets/img/arrow_pc.png) center bottom /100% auto no-repeat;
					}
					&:before {
						content: '';
						display: block;
						position: absolute;
						right: -40px;
						top: 0;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 71px 0 71px 40px;
						border-color: transparent transparent transparent $blue;
						z-index: 1;
						transition-duration: 0.3s;
					}
				}
				&:nth-child(1) {
					a {
						background: #82cdd0;
						&:before {
							border-color: transparent transparent transparent #82cdd0;
						}
						@include media(hover) {
							&:hover {
								background: #5ebfc3;
								&:before {
									border-color: transparent transparent transparent #5ebfc3;
								}
							}
						}
					}
				}
				&:nth-child(2) {
					a {
						background: #70c6ca;
						padding-left: 35px;
						&:before {
							border-color: transparent transparent transparent #70c6ca;
						}
						@include media(hover) {
							&:hover {
								background: #4db8bd;
								&:before {
									border-color: transparent transparent transparent #4db8bd;
								}
							}
						}
					}
				}
				&:nth-child(3) {
					a {
						background: #5ebfc3;
						padding-left: 35px;
						&:before {
							border-color: transparent transparent transparent #5ebfc3;
						}
						@include media(hover) {
							&:hover {
								background: #41aeb3;
								&:before {
									border-color: transparent transparent transparent #41aeb3;
								}
							}
						}
					}
				}
				&:nth-child(4) {
					a {
						background: #4db8bd;
						padding-left: 35px;
						&:before {
							border-color: transparent transparent transparent #4db8bd;
						}
						@include media(hover) {
							&:hover {
								background: #33a5aa;
								&:before {
									border-color: transparent transparent transparent #33a5aa;
								}
							}
						}
					}
				}
				&:nth-child(5) {
					a {
						background: #41aeb3;
						&:before {
							display: none;
						}
						@include media(hover) {
							&:hover {
								background: #2f9ea3;
								&:before {
									border-color: transparent transparent transparent #2f9ea3;
								}
							}
						}
					}
				}
			}
			@include media(m) {
				max-width: vw(650);
				flex-wrap: wrap;
				li {
					width: 100%;
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						a {
							padding: 0 vw(38);
						}
					}
					a {
						position: relative;
						height: vw(122);
						padding: 0 vw(38);
						justify-content: flex-start;
						align-items: center;
						flex-wrap: nowrap;
						align-content: center;
						> span {
							position: relative;
							top: auto;
							display: block;
							width: vw(162);
							span {
								width: vw(162);
								height: vw(40);
								border-radius: vw(100);
								font-size: vw(24);
								box-shadow: none;
							}
						}
						strong {
							text-align: left;
							margin: 0 0 0 vw(32);
							justify-content: flex-start;
						}
						i {
							display: block;
							position: absolute;
							right: vw(38);
							top: vw(40);
							width: vw(38);
							height: vw(38);
							background: url(/oem-pb_assets/img/arrow_sp.png) center bottom /100% auto no-repeat;
						}
						&:before {
							right: calc(50% - 8px);
							top: auto;
							bottom: -10px;
							border-style: solid;
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 10px 8px 0 8px;
						}
					}
					&:nth-child(1) {
						a {
							&:before {
								border-color: #82cdd0 transparent transparent transparent;
							}
						}
					}
					&:nth-child(2) {
						a {
							&:before {
								border-color: #70c6ca transparent transparent transparent;
							}
						}
					}
					&:nth-child(3) {
						a {
							&:before {
								border-color: #5ebfc3 transparent transparent transparent;
							}
						}
					}
					&:nth-child(4) {
						a {
							&:before {
								border-color: #4db8bd transparent transparent transparent;
							}
						}
					}
				}
			}
		}
		&__box {
			max-width: 1024px;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			.-bottom + .-bottom {
				margin-top: 60px;
			}
			.-bottom:last-child {
				.p-oempb__step__box__item {
					&:before {
						display: none;
					}
				}
			}
			&__item {
				display: flex;
				justify-content: space-between;
				position: relative;
				&:before {
					content: '';
					display: block;
					width: 1px;
					height: 60px;
					border-right: 2px dotted $blue;
					position: absolute;
					left: 112px;
					bottom: -60px;
				}
			}
			&__img {
				width: 220px;
				position: relative;
				z-index: 1;
			}
			&__txt {
				width: calc(100% - 260px);
				h3 {
					font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
					color: $blue;
					font-size: 3.2rem;
					margin: 5px 0 5px 0;
					font-weight: 400;
				}
			}
			@include media(m) {
				max-width: vw(650);
				.-bottom {
					+ .-bottom {
						margin-top: 0;
					}
				}
				&__item {
					display: block;
					padding-top: vw(112);
					&:before {
						display: none;
					}
				}
				&__img {
					width: vw(308);
					display: block;
					margin: 0 auto vw(30) auto;
				}
				&__txt {
					width: 100%;
					h3 {
						text-align: center;
						font-size: vw(48);
						margin: 0 0 2.4vw 0;
						line-height: 1.2;
					}
				}
			}
		}
	}
	&__product {
		margin-bottom: 100px;
		h3 {
			margin: 0 38px 0 0;
		}
		.c-flex {
			margin-bottom: 25px;
			justify-content: flex-start;
			align-items: center;
		}
		p.note {
			margin-bottom: 55px;
		}
		h4 {
			margin-bottom: 15px;
		}
		.l-article p:not([class])+h4 {
			margin-top: 55px;
		}
		.l-article .column__03>* {
			margin-bottom: 0;
		}
		@include media(m) {
			margin-bottom: vw(120);
			h3 {
				margin: 0;
			}
			.l-article p:not([class])+h4 {
				margin-top: 6.66667vw;
			}
			.c-flex {
				justify-content: space-between;
				margin-bottom: vw(25);
			}
			p.note {
				margin-bottom: vw(50);
			}
			.l-article .column__03>* {
				width: vw(268);
				&:nth-child(n + 3) {
					margin-top: vw(28);
				}
			}
		}
	}
	.l-aside {
		margin-top: 60px;
		@include media(m) {
			margin-top: 10vw;
		}
	}
}