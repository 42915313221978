.p-strength {	
	&__index {
		margin-bottom: 125px;
		&__tit {	
			margin-bottom: 30px;
			line-height: 1.2;
			letter-spacing: 0.07em;
			&__main {
				font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
				font-size: 5rem;
				font-weight: 400;
				display: block;
				letter-spacing: 0.2em;
			}
			&__sub {
				color: $blue;
				font-size: 1.4rem;
				font-weight: 500;
				display: block;
				margin-bottom: 15px;
				font-family: 'Montserrat', sans-serif;
				letter-spacing: -0.01em;
			}
			@include media(m) {
				margin-bottom: vw(20);
				line-height: 1.4;
				&__main {
					font-size: vw(60);
					letter-spacing: 0.1em;
				}
				&__sub {
					margin-bottom: vw(10);
					font-size: vw(22);
				}
			}
		}
		&__main {
			position: relative;
		}
		.l-inner {
			padding: 80px 0;
		}
		.p-top__tit__main{
			letter-spacing: 0;
		}
		&__titarea {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 32px;
			figure {
				width: 284px;
			}
			> div {
				width: calc(100% - 345px);
			}
		}
		&__list {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
			li {
				padding: 20px 0 15px 0;
				width: calc((100% - (60px * 1)) / 2);
				border-top: 1px solid #dbdee2;
				&:nth-child(n + 3) {
					border-bottom: 1px solid #dbdee2;
				}
			}
			.c-btn {
				padding: 10px 27px;
				span {
					padding-right: 38px;
				}
			}
			&__tit {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;
				h3 {
					font-size: 2.2rem;
					font-weight: 500;
					color: $blue;
					&.-navy {
						color: #3477c6;
					}
					&.-lightgreen {
						color: #70b22b;
					}
					&.-orange {
						color: $orange;
					}
				}
			}
			p {
				font-size: 1.4rem;
			}
		}
		@include media(m) {
			margin-bottom: vw(135);
			.p-top__tit {
				text-align: center;
				margin-bottom: vw(20);
				&__main {
					letter-spacing: 0.1em;
				}
				+ p {
					line-height: 1.3;
				}
			}
			&__wall {
				display: none;
			}
			.l-inner {
				padding: 0;
			}
			&__titarea {
				display: block;
				margin-bottom: vw(44);
				text-align: center;
				figure {
					width: vw(397);
					margin: auto;
				}
				> div {
					width: 100%;
					margin-bottom: vw(60);
				}
			}
			&__list {
				display: block;
				border-bottom: 1px solid #dbdee2;
				li {
					padding: vw(30) 0 vw(25) 0;
					width: 100%;
					border-top: 1px solid #dbdee2;
					&:nth-child(n + 3) {
						border-bottom: none;
					}
				}
				.c-btn {
					padding: vw(25) vw(30) vw(28) vw(30);
					font-size: vw(26);
					line-height: 1;
					span {
						padding-right: vw(50);
						&:after {
							top: calc(50% - 0.7vw);
						}
					}
				}
				&__tit {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: vw(16);
					h3 {
						font-size: vw(35);
					}
				}
				p {
					line-height: 1.3;
					font-size: vw(26);
				}
			}
		}
	}
	&__graph {
		display: flex;
		align-items: center;
		margin-bottom: 56px;
		justify-content: space-between;
		div {
			width: calc(100% - 375px);
			padding-right: 74px;
		}
		h2 {
			position: relative;
			font-size: 2.8rem;
			font-weight: 400;
			margin-bottom: 20px;
			span {
				position: relative;
				z-index: 1;
				background: #fff;
				padding-right: 20px;
			}
			&:before {
				content: '';
				position: absolute;
				top: calc(50% + 1px);
				border-top: 2px dotted #acacac;
				left: 0;
				display: block;
				width: 100%;
				height: 1px;
			}
			&.-green {
				color: #70b22b;
				&:before {
					border-top: 2px dotted #70b22b;
				}
			}
			&.-lightblue {
				color: #30b7b7;
				&:before {
					border-top: 2px dotted #30b7b7;
				}
			}
			&.-blue {
				color: #3477c6;
				&:before {
					border-top: 2px dotted #3477c6;
				}
			}
			&.-orange {
				color: #fa7f49;
				&:before {
					border-top: 2px dotted #fa7f49;
				}
			}
		}
		figure {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 323px;
			img {
				width: 254px;
			}
		}
		@include media(m) {
			margin-bottom: vw(50);
			align-items: flex-start;
			justify-content: space-between;
			h2 {
				font-size: vw(34);
				margin-bottom: 2.8vw;
				span {
					padding-right: vw(24);
				}
				&:before {
					content: '';
					position: absolute;
					top: calc(50% - 1px);
					left: 0;
					display: block;
					width: vw(650);
					height: 1px;
				}
			}
			div {
				width: vw(350);
				order: -1;
				padding: 0;
			}
			figure {
				width: vw(248);
				margin-top: vw(85);
				img {
					width: vw(248);
				}
			}
			p {
				line-height: 1.4;
			}
			&.-index {
				flex-wrap: wrap;
				margin-bottom: 8.66667vw;
				h2 {
					width: 100%;
				}
				div {
					order: -1;
					width: 100%;
					margin-bottom: 7vw;
				}
				figure {
					width: vw(345);
					margin: auto;
					img {
						width: vw(345);
					}
				}
			}
		}
	}
	&__box {
		&.-green {
			h3 {
				color: #70b22b;
			}
			background: #edf4e6;
		}
		&.-lightblue {
			h3 {
				color: #30b7b7;
			}
			background: #eff2f2;
		}
		&.-blue {
			h3 {
				color: #3477c6;
			}
			background: #ebf0f5;
		}
		&.-orange {
			h3 {
				color: #fa7f49;
			}
			background: #fef4f0;
		}
		&:nth-child(1),&:nth-child(5) {
			&:before {
				content: '';
				width: 237px;
				height: 222px;
				position: absolute;
				top: 24px;
				left: -157px;
				background: url(/strengths_assets/img/deco_01.png) center center/contain no-repeat;
			}
		}
		&:nth-child(2) {
			&:before {
				content: '';
				width: 170px;
				height: 214px;
				position: absolute;
				top: -79px;
				right: -132px;
				background: url(/strengths_assets/img/deco_02.png) center center/contain no-repeat;
			}
		}
		&:nth-child(3) {
			&:before {
				content: '';
				width: 198px;
				height: 241px;
				position: absolute;
				top: -5px;
				left: -157px;
				background: url(/strengths_assets/img/deco_03_pc.png) center center/contain no-repeat;
			}
		}
		&:nth-child(4) {
			&:before {
				content: '';
				width: 350px;
				height: 300px;
				position: absolute;
				top: -52px;
				right: -315px;
				background: url(/strengths_assets/img/deco_04_pc.png) center center/contain no-repeat;
			}
		}
		padding: 40px;
		position: relative;
		+ .p-strength__box {
			margin-top: 34px;
		}
		h3 {
			text-align: center;
			line-height: 1.2;
			font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
			margin-bottom: 35px;
			font-weight: 400;
			.-num {
				display: block;
				font-size: 4.8rem;
			}
			.-main {
				font-size: 3.6rem;
				display: block;
				letter-spacing: 0.1em;
			}
		}
		> div {
			display: flex;
			justify-content: space-between;
			div {
				width: calc(100% - 402px);
			}
			figure {
				overflow: hidden;
				border-radius: 4px;
				width: 350px;
				margin-top: 5px;
			}
			p {
				line-height: 1.7;
				+ p {
					margin-top: 22px;
				}
			}
			ul:not([class]) {
				margin-top: 22px;
				> li {
					padding-left: 16px;
					position: relative;
					line-height: 1.3;
					margin-bottom: 6px;
					&:last-child {
						margin-bottom: 0;
					}
					&::before {
						position: absolute;
						top: 8px;
						left: 0;
						content: '';
						display: block;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: #282828;
					}
				}
				+ p {
					margin-top: 22px;
				}
			}
		}
		@include media(m) {
			&:nth-child(1),&:nth-child(5) {
				&:before {
					content: '';
					width: vw(212);
					height: vw(199);
					position: absolute;
					top: -3.66667vw;
					left: -11.73333vw;
					background: url(/strengths_assets/img/deco_01.png) center center/contain no-repeat;
				}
			}
			&:nth-child(2) {
				&:before {
					content: '';
					width: vw(153);
					height: vw(181);
					position: absolute;
					top: vw(-50);
					right: -9.26667vw;
					background: url(/strengths_assets/img/deco_02.png) center center/contain no-repeat;
				}
			}
			&:nth-child(3) {
				&:before {
					content: '';
					width: vw(156);
					height: vw(203);
					position: absolute;
					top: vw(-45);
					left: -10.26667vw;
					background: url(/strengths_assets/img/deco_03_sp.png) center center/contain no-repeat;
				}
			}
			&:nth-child(4) {
				&:before {
					content: '';
					width: vw(186);
					height: vw(225);
					position: absolute;    
					top: -2.73333vw;
					right: -12.5vw;
					background: url(/strengths_assets/img/deco_04_sp.png) center center/contain no-repeat;
				}
			}
			padding: vw(44);
			+ .p-strength__box {
				margin-top: vw(30);
			}
			h3 {
				line-height: 1.4;
				margin-bottom: 3vw;
				.-num {
					font-size: vw(48);
				}
				.-main {
					font-size: vw(42);
				}
			}
			> div {
				display: flex;
				flex-wrap: wrap;
				div {
					width: 100%;
				}
				figure {
					width: 100%;
					order: -1;
					margin-bottom: 5vw;
				}
				p {
					line-height: 1.4;
					+ p {
						margin-top: vw(15);
					}
				}
				ul:not([class]) {
					margin-top: vw(15);
					> li {
						padding-left: vw(30);
						line-height: 1.3;
						margin-bottom: vw(12);
						&::before {
							top: vw(12);
							width: vw(12);
							height: vw(12);
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
					+ p {
						margin-top: vw(15);
					}
				}
			}
		}
	}
}