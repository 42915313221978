.l-indexlist {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	a {
		display: block;
		text-decoration: none!important;
		@include media(hover) {
			&:hover {
				div {
					img {
						transition-duration: 0.5s;
						transform: scale(1.1);
					}
				}
				color: $blue;
			}
		}
	}
	li {
		width: calc((100% - (56px * 1)) / 2);
		margin-bottom: 37px;
	}
	div {
		margin-bottom: 5px;
		overflow: hidden;
		position: relative;
		border-radius: 4px;
		&:before {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 113px;
			background: url(/common/img/grade.png) bottom center/auto 113px repeat-x;
			content: '';
			z-index: 1;
		}
		span {    
			display: block;
			z-index: 2;
			position: absolute;
			bottom: 17px;
			left: 23px;
			width: calc(100% - 58px);
			color: #fff;
			font-size: 2.4rem;
			font-weight: 400;
			i {
				width: 27px;
				height: 27px;
				background: #fff;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				right: 0;
				top: 7px;
				&:before {
					position: relative;
					transform: translate(1px,0);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_gray.svg) bottom center/auto 9px no-repeat;
					content: '';
					display: block;
				}
			}
		}
		img {
			transition-duration: 0.5s;
		}
	}
	@include media(m) {
		flex-wrap: wrap;
		a {
			display: block;
		}
		li {
			width:100%;
			margin-bottom: vw(54);
		}
		div {
			margin-bottom: vw(14);
			border-radius: vw(4);
			&:before {
				height: vw(124);
				background: url(/common/img/grade.png) bottom center/auto vw(124) repeat-x;
			}
			span {
				position: absolute;
				font-size: vw(30);
				bottom: 3.66667vw;
				left: 3.8vw;
				width: calc(100% - 8.46vw);
				z-index: 2;
				i {
					width: vw(38);
					height: vw(38);
					top: 0.8vw;
					background: url(/common/img/ico_indexlist_arrow_sp.png) center center/auto vw(38) no-repeat;
					&:before {
						display: none;
					}
				}
			}
		}
		p {
			line-height: 1.3;
		}
	}
}
