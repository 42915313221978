.l-article {
	&.-products {
		margin-top: 60px;
		@include media(m) {
			margin-top: 9vw;
		}
	}
	> *:last-child {
		margin-bottom: 0!important;
	}
	h2 {
		font-size: 2.4rem;
		border-top: 1px solid #c5c5c5;
		border-bottom: 1px dotted #c5c5c5;
		position: relative;
		padding: 20px 0;
		line-height: 1.2;
		font-weight: 400;
		margin-bottom: 25px;
		&:before {
			content: '';
			display: block;
			width: 58px;
			top: -1px;
			left: 0;
			height: 1px;
			background: $blue;
			position: absolute;
		}
	}
	> * + h2 {
		margin-top: 72px;
	}
	> .p-news__article__list__txt {
		+ h2 {
			margin-top: 0;
		}
	}
	h3 {
		line-height: 1.2em;
		margin-bottom: 10px;
		font-size: 2rem;
		font-weight: 400;
		margin-top: 40px;
		padding: 2px 0 2px 24px;
		position: relative;
		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;
		}
		&.-bgblue {
			background: #f4f7fa;
			padding: 20px 24px;
			&:before {
				display: none;
			}
		}
	}
	h4 {
		line-height: 1.2em;
		margin-bottom: 10px;
		margin-top: 40px;
		font-size: 1.8rem;
		color: $blue;
		font-weight: 500;
	}
	h5 {
		line-height: 1.1em;
		margin-bottom: 10px;
		margin-top: 30px;
		font-size: 1.6rem;
		font-weight: 600;
	}
	> p:not([class]) {
		line-height: 1.8;
		+ p:not([class]) {
			margin-top: 24px;
		}	
	}
	.-red {
		color: #f92519;
	}
	a:not([class]) {
		text-decoration: underline;
		display: inline-block;
		position: relative;
		line-height: 1.8em;
		margin: 0 5px 10px 5px;
		font-weight: 400;
		&:hover {
			text-decoration: none;
		}
		&[href^="tel:"] {
			pointer-events: none;
			text-decoration: none;
			cursor: default;
			margin: 0;
		}
		&[target="_blank"] {
			img {
				+ i {
					display: none!important;
				}
			}
			i {
				width: 11px;
				height: 8px;
				position: relative;
				display: inline-block;
				margin-left: 8px;
				border: 1px solid #282828;
				transform: translate(0,-2px);
				&:before {
					content: '';
					width: 11px;
					height: 8px;
					border-bottom: 1px solid #282828;
					border-left: 1px solid #282828;
					position: absolute;
					bottom: -3px;
					left: -3px;
				}
			}
			&[href$=".pdf"] {
				i {
					width: 11px;
					height: 13px;
					position: relative;
					display: inline-block;
					margin-left: 8px;
					border: none;
					&:before {
						content: '';
						display: block;
						width: 11px;
						height: 13px;
						right: 0;
						position: absolute;
						background: url(/common/img/ico_pdf_black.png) center bottom/cover no-repeat;
						border: none;
					}
				}
			}
		}
		@include media(m) {
			margin: 0 vw(5);
			&[href^="tel:"] {
				pointer-events: auto;
				margin: 0 vw(5);
				text-decoration: underline;
			}
			&[target="_blank"] {
				i {
					width: vw(24);
					height: vw(18);
					margin-left: vw(10);
					&:before {
						width: vw(24);
						height: vw(18);
						bottom: -3px;
						left: -3px;
					}
				}
				&[href$=".pdf"] {
					i {
						width: vw(20);
						height: vw(25);
						margin-left: vw(10);
						&:before {
							width: vw(20);
							height: vw(25);
						}
					}
				}
			}
		}
	}
	.c-btn {
		padding: 15px 24px;
	}
	.flexlist-wrap {
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 33%;
				&.u-w100 {
					width: 100%;
				}
			}
		}
	}
	.float-list {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
		li {
			margin: 0 20px 20px 0;
		}
	}
	ul:not([class]) {
		margin-bottom: 40px;
		> li {
			padding-left: 16px;
			position: relative;
			line-height: 1.3;
			margin-bottom: 6px;
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				position: absolute;
				top: 8px;
				left: 0;
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #282828;
			}
		}
	}
	ul.-note {
		margin-bottom: 40px;
		> li {
			padding-left: 1.5em;
			position: relative;
			line-height: 1.3;
			margin-bottom: 6px;
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				position: absolute;
				left: 0;
				content: '※';
				display: block;
			}
		}
	}
	ul.-taglist {
		display: flex;
		li {
			margin-right: 4px;
		}
		span,a {
			color: $blue;
			font-size: 1.4rem;
			padding: 4px 20px;
			border-radius: 40px;
			background: #fff;
			border: 1px solid $blue;
			display: inline-block;
		}
	}
	ol:not([class]) {
		counter-reset: num; 
		margin-bottom: 40px;
		> li {
			position: relative;
			padding-left: 30px;
			line-height: 1.3;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				counter-increment: num;
				position: absolute;
				top: 2px;
				left: 0;
				content: counter(num)'';
				font-size: 1.1rem;
				font-weight: bold;
				color: #fff;
				background: #282828;
				width: 19px;
				height: 19px;
				border-radius: 50%;
				text-align: center;
				line-height: 19px;
				font-family: 'Poppins', sans-serif;
			}
		}
	}
	.column__02 {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 54px;
		> * {
			 width: calc((100% - (40px * 1)) / 2);
		}
		figure {
			margin-bottom: 0;
			figcaption {
				text-align: center;
				font-size: 1.4rem;
				line-height: 1.2em;
			}
		}

	}
	.column__03 {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		> * {
			 width: calc((100% - (40px * 2)) / 3);
			margin-bottom: 20px;
		}
		&:before,&:after {
			content: '';
			display: block;
			width: calc((100% - (40px * 2)) / 3);
		}
		&:before {
			order: 1;
		}
		figure {
			figcaption {
				text-align: center;
				font-size: 1.4rem;
				line-height: 1.2em;
			}
		}
	}
	.column__img {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 30px;
		> *:first-child {
			width: calc(100% - 390px);
		}
		> *:last-child {
			width: 350px;
		}
		p:last-child{
			margin-bottom: 0;
		}
		figure {
			margin-bottom: 0;
		}
		p {
			line-height: 1.8;
			+ p {
				margin-top: 24px;
			}	
		}
	}
	figure {
		margin-bottom: 36px;
		text-align: center;
		&.-pcmbS {
			margin-bottom: 30px;
		}
		&.-notr {
			img {
				border-radius: 0px;
			}
		}
		&.-imgborder {
			img {
				border:1px solid #dbdee2;
			}
		}
		&.-imgborderpd{
			img {
				border:1px solid #dbdee2;
				padding:24px;
			}
		}
		img {
			border-radius: 6px;
			display: inline-block;
			margin-bottom: 18px;
			+ figcaption {
				margin-top: 0;
			}
		}
		figcaption {
			text-align: center;
			font-size: 1.4rem;
			line-height: 1.6em;
			margin-top: 20px;
		}
		a {
			pointer-events: auto;
			position: relative;
		}
	}
	.blockquote {
		padding: 26px 32px;
		background: #f3f2f2;
		margin-bottom: 20px;
		p {
			line-height: 1.8em;
			&:last-child {
				margin-bottom: 0;
			}
		}
		ul:not([class]) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.color-box {
		padding: 16px;
		margin: 40px 0 120px 0!important;
		border-radius: 6px;
		p {
			font-size: 1.8rem;
			line-height: 1.4em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	p.note {
		font-size: 1.4rem;
		padding-left: 1.5rem;
		text-indent: -1.5rem;
		margin-bottom: 40px;
		margin-top: 5px;
	}
	p.-annotation {
		margin-top: 5px;
		font-size: 1.4rem;
	}
	.table__cell {
		margin-bottom: 36px;
		td,th {
			padding: 18px 32px;
			border: 1px solid #d9e3ec;
			&:first-child {
				min-width: 160px;
				max-width: 204px;
			}
		}
		th {
			font-weight: 600;
			background: #f4f7fa;
		}
		td {
			vertical-align: text-top;
		}
		ul:not([class]) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.table__notcell {
		margin-bottom: 36px;
		td,th {
			padding: 17px 22px;
			border: 2px solid #fff;
			background: #f4f7fa;
			&:first-child {
				min-width: 275px;
				&.-mw100 {
					min-width: 100px;
				}
			}
		}
		tr:nth-child(even) td,tr:nth-child(even) th {
			background: #fff;
		}
		th {
			font-weight: 600;
		}
		td {
			vertical-align: text-top;
			padding-left: 37px;
		}
		ul:not([class]) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.img a{
		pointer-events: auto;
		position: relative;
		display: block;
		line-height: 1;
	}
	.img {
		position: relative;
	}
	.img img {
		display: block;
	}
	.img .expand_area{
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.expand_bg {
		width: 100%;
		height: 100%;
		background: #333;
		position: absolute;
		opacity: .5;
	}
	.expand_txt {
		content:'';
		position: absolute;
		display:inline-block;
		width: 238px;
		height: 25px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		/*background:url(/common/img/img_expand_hint.png) no-repeat;*/
		background-size:contain;
		z-index: 1;
	}
	&__bggray {
		padding: 40px;
		background: #f3f2f2;
		+ .l-article__bggray {
			margin-top: 25px;
		}
	}
	p:not([class]) + div,p:not([class]) + h4,p:not([class]) + ul,p:not([class]) + ol,p:not([class]) + table,p:not([class]) + figure,p.copy + figure,.l-article__spec + h3 {
		margin-top: 60px;
	}
	ol:not([class]) + h3{
		margin-top: 60px;
	}
	p:not([class]) + h3 {
		margin-top: 40px;
	}
	.-mbL {
		margin-bottom: 120px!important;
	}
	.-mt0 {
		margin-top: 0!important;
	}
	@include media(m) {
		h2 {
			font-size: vw(34);
			padding: vw(33) 0;
			margin-bottom: 4.4vw;
			&:before {
				width: vw(58);
			}
		}
		> * + h2 {
			margin-top: 12.3vw;
		}
		p + .tit__m,figure + .tit__m {
			margin-top: vw(50);
		}
		.c-btn {
			padding: vw(26) vw(40);
			text-align: left;
			justify-content: flex-start;
		}
		h3 {
			margin-top: vw(19);
			line-height: 1.2em;
			margin-bottom: vw(38);
			font-size: vw(32);
			font-weight: 400;
			padding: 0 0 0 vw(38);
			&.-bgblue {
				padding: vw(24) vw(38);
			}
		}
		h4 {
			margin-top: vw(19);
			line-height: 1.2;
			margin-bottom: vw(18);
			font-size: vw(30);
			font-weight: 600;
		}
		h5 {
			margin-top: vw(19);
			font-size: vw(28);
			margin-bottom: vw(18);
		}
		> p:not([class]) {
			line-height: 1.4em;
			margin-bottom: vw(20);
			+ p:not([class]) {
				margin-top: vw(20);
			}	
		}
		ul:not([class]) {
			margin-bottom: vw(48);
			> li {
				padding-left: vw(30);
				line-height: 1.3;
				margin-bottom: vw(12);
				&::before {
					top: vw(12);
					width: vw(12);
					height: vw(12);
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		ul.-taglist {
			li {
				margin-right: vw(3);
			}
			span,a {
				font-size: vw(24);
				padding: 0.6vw 4vw;
				border-radius: vw(100);
			}
		}
		ol:not([class]) {
			margin-bottom: vw(48);
			> li {
				padding-left: vw(50);
				margin-bottom: vw(12);
				&::before {
					top: vw(6);
					font-size: vw(18);
					width: vw(30);
					height: vw(30);
					line-height: vw(30);
					font-weight: 500;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.column__02 {
			> * {
				width: 100%;
				margin-bottom: vw(20);
			}
			figure {
				margin-bottom: 4.4vw;
				figcaption {
					font-size: vw(24);
					line-height: 1.4em;
				}
			}
		}
		.column__03 {
			> * {
				width: 100%;
				margin-bottom: vw(20);
			}
			figure {
				figcaption {
					font-size: vw(24);
					line-height: 1.4em;
				}
			}
		}
		.column__img {
			margin-bottom: vw(90);
			> *:first-child {
				width: 100%;
			}
			> *:last-child {
				width: 100%;
			}
			p:last-child{
				margin-bottom: 0;
			}
			ul:last-child {
				margin-bottom: vw(24);
			}
		}
		.-sporder-1 {
			order: -1;
		}
		figure {
			margin-bottom: 4.4vw;
			&.-pcmbS {
				margin-bottom: vw(48);
			}
			&.-notr {
				img {
					border-radius: 0px;
				}
			}
			&.-imgborderpd{
				img {
					padding: vw(20);
				}
			}
			img {
				border-radius: vw(6);
				margin-bottom: vw(10);
			}
			figcaption {
				font-size: vw(24);
				line-height: 1.4em;
				margin-top: vw(18);
			}
		}
		.blockquote {
			padding: vw(36);
			margin-bottom: vw(48);
			p {
				line-height: 1.6em;
			}
		}
		.color-box {
			padding: vw(22) vw(42);
			border-radius: vw(6);
			margin: vw(28) 0 vw(152) 0!important;
			p {
				font-size: vw(30);
				line-height: 1.3em;
			}
		}
		p.note {
			margin-bottom: vw(48);
			font-size: vw(24);
			margin-top: vw(5);
			&.u-aR {
				text-align: left;
			}
		}
		.-annotation {
			font-size: vw(24);
		}
		.table__cell {
			margin-bottom: vw(48);
			td,th {
				padding: vw(24) vw(20);
				border: 1px solid #ececec;
				&:first-child {
					min-width: 17.86667vw;
					&.-spw25 {
						min-width: 25vw;
					}
				}
			}
			th {
				font-weight: 600;
				background: #f4f7fa;
				padding: vw(24) vw(12);
			}
			&.-spblock {
				th,td {
					display: block;
					max-width: 100%!important;
				}
				th {
					border-top: none;
				}
				thead {
					tr{
						&:first-child {
							th {
								border-top: 1px solid #ececec;
							}
						}
					}	
				}
				td {
					border-top: none;
				}
			}
		}
		.table__notcell {
			margin-bottom: vw(48);
			td,th {
				display: block;
				padding: vw(24) vw(39);
				background: #fff;
				line-height: 1.4;
				&:first-child {
					min-width: vw(284);
					&.-spw25 {
						min-width: 25vw;
					}
				}
			}
			th {
				background: #f4f7fa;
				text-align: left;
			}
			tr:nth-child(even) th {
				background: #f4f7fa;
			}
		}
		p:not([class]) + div,p:not([class]) + h3,p:not([class]) + h4,p:not([class]) + ul,p:not([class]) + ol,p:not([class]) + table,p:not([class]) + figure,p.copy + figure,.l-article__spec + h3 {
			margin-top: 11.4vw;
		}
		ol:not([class]) + h3 {
			margin-top: 11.4vw;
		}
		.-spmtL {
			margin-top: vw(100)!important;
		}
		.scroll-hint-icon {
			top: 16vw;
			padding: 0;
			width: vw(191);
			height: vw(194);
			background: url(/common/img/scroll_hint.png) center bottom/cover no-repeat;
			&:before,&:after {
				display: none;
			}
		}
		.scroll-hint-text {
			display: none;
		}
		.js-scrollable {
			width: vw(700);
			margin-bottom: vw(48);
			table{
				width: max-content;
				max-width: vw(1500);
				margin-bottom: 0;
			}
		}
		.scroll-hint-icon-wrap.is-active .scroll-hint-icon {
			opacity: 1;
		}
		.-mbL {
			margin-bottom: vw(160)!important;
		}
		&__bggray {
			padding: vw(38);
			+ .l-article__bggray {
				margin-top: vw(30);
			}
		}
	}

	&__wrap {
		margin-bottom: 75px;
		&.-recipeswrap {
			margin-bottom: 115px;
			@include media(m) {
				margin-bottom: vw(115);
			}
		}
		@include media(m) {
			margin-bottom: 9vw;
		}
	}
	&__main {
		margin-bottom: 56px;
		h2 {
			font-size: 2.4rem;
			padding: 14px 0 18px 0px;
			position: relative;
			border-top: 1px solid #c5c5c5;
			border-bottom: 1px dotted #c5c5c5;
			margin-bottom: 40px;
			font-weight: 400;
			&:before {
				content: '';
				display: block;
				width: 58px;
				height: 1px;
				position: absolute;
				top: -1px;
				left: 0;
				background: $blue;
			}
		}
		@include media(m) {
			margin-bottom: vw(110);
			h2 {
				font-size: vw(34);
				padding: 3.2vw 0 4vw 0px;
				margin-bottom: vw(56);
				line-height: 1.3;
				&:before {
					width: vw(57);
				}
			}
		}
	}
	&__about {
		display: flex;
		justify-content: space-between;
		@include media(m) {
			flex-wrap: wrap;
		}
		figure {
			width: 350px;
			height: 240px;
			border-radius: 4px;
			overflow: hidden;
			img {
				width: 350px;
				height: 240px;
				-o-object-fit: cover;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
			@include media(m) {
				width: 100%;
				height: vw(443);
				border-bottom-right-radius: vw(4);
				margin-bottom: vw(56);
				img {
					width: 100%;
					height: vw(443);
					border-bottom-right-radius: vw(4);
				}
			}
		}
		&__text {
			width: calc(100% - 392px);
			@include media(m) {
				width: 100%;
			}
			&__list {
				display: flex;
				flex-wrap: wrap;
				a {
					margin: 0 4px 8px 0;
					border-radius: 50px;
					background: $blue;
					border: 1px solid $blue;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 30px;
					padding: 2px 20px;
					font-size: 1.4rem;
					@include media(hover) {
						&:hover {
							background: #fff;
							color: $blue;
						}
					}
				}
				@include media(m) {
					margin-bottom: vw(10);
					a {
						margin: 0 vw(5) vw(8) 0;
						border-radius: vw(100);
						padding: 0.6vw vw(34) 0.8vw vw(34);
						font-size: vw(24);
						min-height: vw(54);
						@include media(hover) {
							&:hover {
								background: #fff;
								color: $blue;
							}
						}
					}
				}
			}
			&__formlist {
				display: flex;
				flex-wrap: wrap;
				li {
					margin: 0 4px 8px 0;
				}
				li > span {
					border-radius: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 3px 20px 4px 20px;
					min-height: 30px;
					font-size: 1.4rem;
					line-height: 1;
					@media all and (-ms-high-contrast: none) {
					  padding: 6px 20px 4px 20px;
					}
					img {
						transition-duration: 0.3s;
					}
					i {
						display: block;
						line-height: 1;
						margin-right: 8px;
					}
					&.-drink {
						border: 1px solid #0168b7;
						color: #0168b7;
						i {
							width: 15px;
							height: 18px;
							background: url(/common/img/svgico/form/color/ico_drink.svg) center center/contain no-repeat;
							img {
								width: 15px;
							}
						}
					}
					&.-jelly {
						border: 1px solid #ddd100;
						color: #ddd100;
						i {
							width: 18px;
							height: 18px;
							background: url(/common/img/svgico/form/color/ico_jelly.svg) center center/contain no-repeat;
							img {
								width: 18px;
							}
						}
					}
					&.-tablet {
						border: 1px solid #019e97;
						color: #019e97;
						i {
							width: 19px;
							height: 17px;
							background: url(/common/img/svgico/form/color/ico_tablet.svg) center center/contain no-repeat;
							img {
								width: 19px;
							}
						}
					}
					&.-soft {
						border: 1px solid #1d2089;
						color: #1d2089;
						i {
							width: 16px;
							height: 16px;
							background: url(/common/img/svgico/form/color/ico_soft.svg) center center/contain no-repeat;
							img {
								width: 16px;
							}
						}
					}
					&.-hard {
						border: 1px solid #920784;
						color: #920784;
						i {
							width: 16px;
							height: 16px;
							background: url(/common/img/svgico/form/color/ico_hard.svg) center center/contain no-repeat;
							img {
								width: 16px;
							}
						}
					}
					&.-powder {
						border: 1px solid #ce2884;
						color: #ce2884;
						i {
							width: 22px;
							height: 15px;
							background: url(/common/img/svgico/form/color/ico_powder.svg) center center/contain no-repeat;
							img {
								width: 22px;
							}
						}
					}
					&.-bread {
						border: 1px solid #c90050;
						color: #c90050;
						i {
							width: 18px;
							height: 17px;
							background: url(/common/img/svgico/form/color/ico_bread.svg) center center/contain no-repeat;
							img {
								width: 18px;
							}
						}
					}
					&.-meat {
						border: 1px solid #e70012;
						color: #e70012;
						i {
							width: 21px;
							height: 14.81px;
							background: url(/common/img/svgico/form/color/ico_meat.svg) center center/contain no-repeat;
							img {
								width: 21px;
							}
						}
					}
					&.-sidedish {
						border: 1px solid #149d64;
						color: #149d64;
						i {
							width: 22px;
							height: 15px;
							background: url(/common/img/svgico/form/color/ico_sidedish.svg) center center/contain no-repeat;
							img {
								width: 22px;
							}
						}
					}
					&.-noodles {
						border: 1px solid #8fc320;
						color: #8fc320;
						i {
							width: 23px;
							height: 18px;
							background: url(/common/img/svgico/form/color/ico_noodles.svg) center center/contain no-repeat;
							img {
								width: 23px;
							}
						}
					}
					&.-water {
						border: 1px solid #00a0ea;
						color: #00a0ea;
						i {
							width: 15px;
							height: 21px;
							background: url(/common/img/svgico/form/color/ico_water.svg) center center/contain no-repeat;
							img {
								width: 15px;
							}
						}
					}
					&.-oil {
						border: 1px solid #f28c00;
						color: #f28c00;
						i {
							width: 15px;
							height: 21px;
							background: url(/common/img/svgico/form/color/ico_oil.svg) center center/contain no-repeat;
							img {
								width: 15px;
							}
						}
					}
				}
				@include media(m) {
					li {
						margin: 0 vw(5) vw(8) 0;
					}
					li > span {
						border-radius: vw(100);
						padding: 0.6vw vw(32) 0.8vw vw(32);
						font-size: vw(24);
						min-height: vw(54);
						i {
							margin-right: vw(7);
						}
						&.-drink {
							border: 1px solid #0168b7;
							color: #0168b7;
							i {
								width: vw(28);
								height: vw(35);
								img {
									width: vw(28);
								}
							}
						}
						&.-jelly {
							border: 1px solid #ddd100;
							color: #ddd100;
							i {
								width: vw(31);
								height: vw(31);
								img {
									width: vw(31);
								}
							}
						}
						&.-tablet {
							border: 1px solid #019e97;
							color: #019e97;
							i {
								width: vw(32);
								height: vw(29);
								img {
									width: vw(32);
								}
							}
						}
						&.-soft {
							border: 1px solid #1d2089;
							color: #1d2089;
							i {
								width: vw(32);
								height: vw(32);
								img {
									width: vw(32);
								}
							}
						}
						&.-hard {
							border: 1px solid #920784;
							color: #920784;
							i {
								width: vw(32);
								height: vw(32);
								img {
									width: vw(32);
								}
							}
						}
						&.-powder {
							border: 1px solid #ce2884;
							color: #ce2884;
							i {
								width: vw(37);
								height: vw(26);
								img {
									width: vw(37);
								}
							}
						}
						&.-bread {
							border: 1px solid #c90050;
							color: #c90050;
							i {
								width: vw(34);
								height: vw(31);
								img {
									width: vw(34);
								}
							}
						}
						&.-meat {
							border: 1px solid #e70012;
							color: #e70012;
							i {
								width: vw(37);
								height: vw(28);
								img {
									width: vw(37);
								}
							}
						}
						&.-sidedish {
							border: 1px solid #149d64;
							color: #149d64;
							i {
								width: 4.73333vw;
								height: 3.2vw;
								img {
									width: 4.73333vw;
								}
							}
						}
						&.-noodles {
							border: 1px solid #8fc320;
							color: #8fc320;
							i {
								width: vw(35);
								height: vw(28);
								img {
									width: vw(35);
								}
							}
						}
						&.-water {
							border: 1px solid #00a0ea;
							color: #00a0ea;
							i {
								width: vw(21);
								height: vw(28);
								img {
									width: vw(21);
								}
							}
						}
						&.-oil {
							border: 1px solid #f28c00;
							color: #f28c00;
							i {
								width: vw(21);
								height: vw(28);
								img {
									width: vw(21);
								}
							}
						}
					}
				}
			}
			p {
				margin-top: 20px;
				@include media(m) {
					margin-top: vw(26);
					line-height: 1.3;
				}
			}
		}
	}
	&__spec {
		table {
			font-size: 1.4rem;
			td,th {
				padding: 21px 22px;
				border: 2px solid #fff;
				background: #f4f7fa;
				&:first-child {
					min-width: 300px;
				}
			}
			tr:nth-child(even) td,tr:nth-child(even) th {
				background: #fff;
			}
			th {
				font-weight: 600;
			}
			td {
				vertical-align: text-top;
				width: 100%;
			}
			@include media(m) {
				td,th {
					display: block;
					padding: vw(24) vw(39);
					background: #fff;
					line-height: 1.4;
					&:first-child {
						min-width: vw(284);
						&.-spw25 {
							min-width: 25vw;
						}
					}
				}
				th {
					background: #f4f7fa;
					text-align: left;
				}
				tr:nth-child(even) th {
					background: #f4f7fa;
				}
			}
		}
	}
	/*コンソーシアム共通*/
	.expand {
		pointer-events: auto;
		position: relative;
	}
	.expand_bg {
		width: 100%;
		height: 100%;
		background: #333;
		position: absolute;
		opacity: .5;
	}
	.expand_txt {
		content:'';
		position: absolute;
		display:inline-block;
		width: 238px;
		height: 25px;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		background:url(/common/img/img_expand_hint.png) no-repeat;
		background-size:contain;
		z-index: 1;
	}	
}