.c-tit__center {
	position: relative;
	text-align: center;
	color: $blue;
	font-size: 2.6rem;
	margin-bottom: 30px;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 1px; 
		background: $blue;
		position: absolute;
		left: 0;
		top: 50%;
	}
	span {
		position: relative;
		z-index: 1;
		display: inline-block;
		background: #fff;
		padding: 0 22px;
		font-weight: 400;
	}
	@include media(m) {
		font-size: vw(32);
		margin-bottom: vw(32);
		span {
			padding: 0 vw(22);
		}
	}
}
.c-tit__l {
	text-align: center;
	margin-bottom: 30px;
    font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
    font-size: 4.2rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
	span {    display: block;
		margin-bottom: 0px;
		color: #4db8bd;
		font-family: 'Poppins', sans-serif;
		font-size: 1.3rem;
		font-weight: 500;
	}
	@include media(m) {
		font-size: vw(48);
		margin-bottom: vw(32);
		span {
			padding: 0 vw(2);
			font-size: vw(22);
			letter-spacing: -0.02em;
		}
	}
}
.c-tit__mincho {
	margin-bottom: 30px;
	line-height: 1.2;
	letter-spacing: 0.07em;
	&__main {
		font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
		font-size: 5rem;
		font-weight: 400;
		display: block;
		letter-spacing: 0.2em;
	}
	&__sub {
		color: $blue;
		font-size: 1.4rem;
		font-weight: 500;
		display: block;
		margin-bottom: 15px;
		font-family: 'Montserrat', sans-serif;
		letter-spacing: -0.01em;
	}
	@include media(m) {
		margin-bottom: vw(20);
		line-height: 1.4;
		&__main {
			font-size: vw(60);
			letter-spacing: 0.1em;
		}
		&__sub {
			margin-bottom: vw(10);
			font-size: vw(22);
		}
	}
}