.c-formico {
	border: 1px solid #fff;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 31px;
	height: 31px;
	&.form-drink {
		background: #0168b7;
		img {
			width: 16px;
			height: 24px;
		}
	}
	&.form-jelly {
		background: #ddd100;
		img {
			width: 21px;
			height: 21px;
		}
	}
	&.form-tablet {
		background: #019e97;
		img {
			width: 22px;
			height: 20px;
		}
	}
	&.form-soft {
		background: #1d2089;
		img {
			width: 22px;
			height: 22px;
		}
	}
	&.form-hard {
		background: #920784;
		img {
			width: 22px;
			height: 22px;
		}
	}
	&.form-powder {
		background: #ce2884;
		img {
			width: 25px;
			height: 17px;
		}
	}
	&.form-bread {
		background: #c90050;
		img {
			width: 23px;
			height: 21px;
		}
	}
	&.form-meat {
		background: #e70012;
		img {
			width: 25px;
			height: 19px;
		}
	}
	&.form-sidedish {
		background: #009a44;
		img {
			width: 22px;
			height: 22px;
		}
	}
	&.form-noodles {
		background: #8fc320;
		img {
			width: 34px;
			height: 19px;
		}
	}
	&.form-water {
		background: #00a0ea;
		img {
			width: 20px;
			height: 20px;
		}
	}
	&.form-oil {
		background: #f39801;
		img {
			width: 20px;
			height: 20px;
		}
	}
	@include media(m) {
		width: vw(48);
		height: vw(48);
		&.form-drink {
			img {
				width: vw(23);
				height: vw(37);
			}
		}
		&.form-jelly {
			img {
				width: vw(33);
				height: vw(32);
			}
		}
		&.form-tablet {
			img {
				width: vw(34);
				height: vw(31);
			}
		}
		&.form-soft {
			img {
				width: vw(34);
				height: vw(34);
			}
		}
		&.form-hard {
			img {
				width: vw(34);
				height: vw(34);
			}
		}
		&.form-powder {
			img {
				width: vw(38);
				height: vw(27);
			}
		}
		&.form-bread {
			img {
				width: vw(35);
				height: vw(32);
			}
		}
		&.form-meat {
			img {
				width: vw(39);
				height: vw(29);
			}
		}
		&.form-sidedish {
			img {
				width: vw(38);
				height: vw(38);
			}
		}
		&.form-noodles {
			img {
				width: vw(37);
				height: vw(29);
			}
		}
		&.form-water {
			img {
				width: vw(30);
				height: vw(30);
			}
		}
		&.form-oil {
			img {
				width: vw(30);
				height: vw(30);
			}
		}
	}
}
