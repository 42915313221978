.p-single {
	.l-recommend {
		margin-bottom: 100px;
		@include media(m) {
			margin-bottom: 21.06667vw;
		}
		&.-productssingle {
			margin-bottom: 100px;
			@include media(m) {
				margin-bottom: 15.06667vw;
			}
		}
	}
	&.-products {
		.-imgborder img{
			border:1px solid #dbdee2;
		}
		@media only screen and (max-width: 768px){
			.l-article .column__img>*:first-child {
				margin-bottom: 10px;
			}
			.l-article .column__img {
			}
		}
		sup {
			font-size: 100%;
			top: -0.1em;
		}
		.-imgrendering img{
			image-rendering: -webkit-optimize-contrast;
		}
		.l-article .column__04{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.l-article .column__04>*{
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc((100% - (10px * 3)) / 4);
			margin-bottom: 10px;	
			text-align: center;
			line-height: 1.3;
			font-size: 1.5rem;
		}
		.l-article .-featurelist li{
			background: #f4f7fa;
			text-align: center;
			padding:14px 0;
		}
		.l-article__spec table.table__information th:first-child {
			width: 200px;
		}
		.l-article__visual{
			margin-bottom:34px;
		}
		.l-article__visual img{
			border-radius: 6px;
		}
		.p-products__sub__tit {
			display: flex;
			align-items: top;
			margin-bottom:10px;
		}
		.p-products__sub__tit h5{
			margin:7px 10px 0 0;
		}
		.table__cell.-size__s{
			text-align: center
		}
		.table__cell.-size__s th,
		.table__cell.-size__s td{
			padding: 14px 18px;
			line-height: 1.4;
		}
		.table__cell.-size__s td{
			vertical-align: middle;
		}
		.table__cell.-size__s figure,
		.table__cell.-size__s figure img{
			margin-bottom: 0;
		}
		.table__cell.-size__s figure img{
			width: 150px;
			image-rendering: -webkit-optimize-contrast;
		}
		@media only screen and (max-width: 768px){
			.-imgrendering img{
				image-rendering: auto;
			}
			.l-article .column__04>*{
				width: calc((100% - (10px * 1)) / 2);
				margin-bottom: 0;
				padding:7px 0 2px 0;
			}
			.l-article .column__04 figure,
			.l-article .column__04 figure img{
				margin-bottom: 0;
			}
			.l-article .-featurelist li{
				margin-bottom: 7px;
			}
			.l-article__spec table.table__information th:first-child {
				width: 100%;
			}
			.l-article__visual img{
				border-radius: 0.8vw;
			}
			.p-products__sub__tit {
				display: block;
			}
			.p-products__sub__tit h5{
				margin:0;
			}
			.table__cell.-size__s figure img{
				image-rendering: auto;
			}
		}
		
		
	}
}