@charset "UTF-8";

.u-pc {
	display: inherit;
	@include media(m) {
		display: none!important;
	}
}
.u-sp {
	display: none;
	@include media(m) {
		display: inherit;
	}
}