.p-top {
	&__tit{
		margin-bottom: 30px;
		line-height: 1.2;
		letter-spacing: 0.07em;
		&__main {
			font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
			font-size: 5rem;
			font-weight: 400;
			display: block;
			letter-spacing: 0.2em;
		}
		&__sub {
			color: $blue;
			font-size: 1.4rem;
			font-weight: 500;
			display: block;
			margin-bottom: 15px;
			font-family: 'Montserrat', sans-serif;
			letter-spacing: -0.01em;
		}
		@include media(m) {
			margin-bottom: vw(20);
			line-height: 1.4;
			&__main {
				font-size: vw(60);
				letter-spacing: 0.1em;
			}
			&__sub {
				margin-bottom: vw(10);
				font-size: vw(22);
			}
		}
	}
	&__mv {
		margin-bottom: 135px;
		position: relative;
		@include media(m) {
			margin-bottom: vw(155);
			margin-top: vw(80);
		}
		&__tit {
			max-width: 1280px;
			width: 100%;
			margin: 0 auto 60px auto;
			@include media(m) {
				margin-bottom: vw(55);
				width: vw(650);
			}
		}
		&__wrap {
			position: relative;
		}
		.slick-dotted.slick-slider {
			margin: 0!important;
		}
		&__slide {
			position: relative;
			img {
				width: 100%;
			}
			.slick-dotted.slick-slider{
				margin: 0;
			}
			.slick-dots {    
				position: absolute;
				right: 0;
				bottom: -40px;
				width: 100%;
				@include media(m) {
					right: 0;
					bottom: vw(-261);
					top: auto;
				}
				li {
					position: relative;
					display: inline-block;
					width: 12px;
					height: 12px;
					margin: 0 3px;
					padding: 0;
					cursor: pointer;
					@include media(m) {
						width: vw(18);
						height: vw(18);
						margin: 0 vw(3);
					}
					&.slick-active {
						button {
							&::before {
								opacity: 1;
								background: $blue;
							}
						}
					}
					button {
						font-size: 0;
						line-height: 0;
						display: block;
						width: 10px;
						height: 10px;
						padding: 5px;
						cursor: pointer;
						border: 0;
						outline: none;
						border: 1px solid $blue;
						background: #fff;
						border-radius: 50%;
						position: relative;
						&::before {
							line-height: 0;
							position: absolute;
							top: 0;
							left: 0;
							width: 10px;
							height: 10px;
							text-align: center;
							background: #fff;
							border-radius: 50%;
							opacity: 0;
							content: '';
						}
						@include media(m) {
							width: vw(18);
							height: vw(18);
							padding: vw(5);
							&::before {
								width: vw(16);
								height: vw(16);
								top: 0;
								left: 0;
								transform: translate(-0.1vw,-0.1vw);
							}
						}
					}
				}
			}
		}
		&__news {
			max-width: 1024px;
			margin: auto;
			@media all and (-ms-high-contrast: none) {
				left: calc(50% - 512px);
			}
			&__wrap {
				background: rgba(255,255,255,0.9);
				padding: 20px 0;
				position: absolute;
				bottom: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
			}
			a {
				display: flex;
				align-items: center;
				@include media(hover) {
					&:hover {
						.p-top__mv__news__txt {
							color: $blue;
							&:after {
								background: url(/common/img/ico_arrownews_blue.png) center bottom/100% auto;
							}
						}
					}
				}
			}
			&__tag {
				display: block;
				text-align: center;
				margin-right: 24px;
				border-radius: 50px;
				padding: 5px 5px;
				white-space: nowrap;
				color: #fff;
				font-weight: 500;
				width: 188px;
				z-index: 1;
				  background-image: -moz-linear-gradient( -90deg, rgb(50,218,172) 0%, rgb(88,166,205) 100%);
				  background-image: -webkit-linear-gradient( -90deg, rgb(50,218,172) 0%, rgb(88,166,205) 100%);
				  background-image: -ms-linear-gradient( -90deg, rgb(50,218,172) 0%, rgb(88,166,205) 100%);
				  background-image: linear-gradient( -90deg, rgb(50,218,172) 0%, rgb(88,166,205) 100%);
			}
			&__txt {
				width: calc(100% - 210px);
				font-size: 1.4rem;
				padding-right: 20px;
				position: relative;
				&:after {
					content: '';
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 0;
					top: calc(50% - 5px);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto;
					transition-duration: 0.3s;
				}
			}
			@include media(m) {
				width: 100%;
				&__wrap {
					position: relative;
					bottom: auto;
					left: auto;
					flex-wrap: wrap;
					padding: 0;
					background: none;
					transform: translateY(-3.6vw);
				}
				a {
					flex-wrap: wrap;
					padding: vw(27) 0 0 0;
					position: relative;
				}
				&__tag {
					position: absolute;
					margin: 0;
					font-size: vw(28);
					top: vw(0);
					height: vw(54);
					padding: 0;
					width: vw(338);
					left: vw(208);
					line-height: vw(54);
				}
				&__txt {
					width: 100%;
					font-size: 1.4rem;
					padding: vw(50) vw(77) vw(50) vw(30);
					background: #f1f4f7;
					line-height: 1.4;
					&:after {
						content: '';
						display: inline-block;
						margin-left: 10px;
						position: absolute;
						right: vw(36);
						top: calc(50% - 5px);
						width: 5px;
						height: 9px;
						background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto;
						transition-duration: 0.3s;
					}
				}
			}
		}
	}
	&__search {
		margin-bottom: 125px;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 238px;
			height: 273px;
			top: 15px;
			left: 0;
			background: url(/parts/img/search_deco_01_pc.png) center center/cover no-repeat;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 220px;
			height: 611px;
			top: -112px;
			right: 0;
			background: url(/parts/img/search_deco_02_pc.png) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(120);
			.p-top__tit {
				margin-bottom: vw(50);
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: vw(149);
				height: vw(224);
				top: vw(-90);
				left: 0;
				background: url(/parts/img/search_deco_01_sp.png) center center/cover no-repeat;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: vw(217);
				height: vw(339);
				top: vw(-200);
				right: vw(-10);
				background: url(/parts/img/search_deco_02_sp.png) center center/cover no-repeat;
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&__item {
				width: calc((100% - (24px * 2)) / 3);
				margin-bottom: 24px;
				@include media(hover) {
					&.animate {
					  opacity: 0;
					  transform: translateY(20px);
					  transition: all 1.0s;
					}
					&.animate.show {
					  opacity: 1;
					  transform: translateY(0);
					}
					&.animate.show:nth-of-type(1) {
					  transition-delay: 0s;
					}
					&.animate.show:nth-of-type(2) {
					  transition-delay: 0.2s;
					}
					&.animate.show:nth-of-type(3) {
					  transition-delay: 0.4s;
					}
					&.animate.show:nth-of-type(4) {
					  transition-delay: 0.6s;
					}
					&.animate.show:nth-of-type(5) {
					  transition-delay: 0.8s;
					}
					&.animate.show:nth-of-type(6) {
					  transition-delay: 1.0s;
					}
				}

			}
			a {
				background: $blue;
				display: flex;
				border-radius: 2px;
				justify-content: center;
				align-items: center;
				padding: 0 20px 0 30px;
				min-height: 117px;
				border: 1px solid $blue;
				&.-bggreen {
					background: $green;
					border: 1px solid $green;
					.p-top__search__list__txt {
						span {
							color: #6cd0a7;
						}
					}
				}
				@include media(hover) {
					&:hover {
						background: #fff;
						.p-top__search__list__txt {
							span {
								color: #a0dde0!important;
							}
							p {
								color: $blue;
							}
						}
						.p-top__search__list__ico {
							background: #dbf3f4!important;
						}
					}
					&.-bggreen {
						&:hover {
							.p-top__search__list__txt {
								span {
									color: #95dbbe!important;
								}
								p {
									color: $green;
								}
							}
							.p-top__search__list__ico {
								background: #e2f8ef!important;
							}
						}
					}
				}
			}
			&__ico {
				background: #fff;
				width: 75px;
				height: 75px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				margin-right: 22px;
			}
			img {
				&.-ico01 {
					width: 38px;
				}
				&.-ico02 {
					width: 52px;
				}
				&.-ico03 {
					width: 37px;
					transform: translate(5px,0);
				}
				&.-ico04 {
					width: 46px;
				}
				&.-ico05 {
					width: 32px;
				}
				&.-ico06 {
					width: 35px;
					transform: translate(4px,0);
				}
			}
			&__txt {
				width: 160px;
				line-height: 1.3;
				span {
					font-size: 1.1rem;
					color: #a0dde0;
					display: block;
					line-height: 1.2;
					letter-spacing: 0.07em;
					margin-bottom: 5px;
				}
				p {
					color: #fff;
					font-size: 2rem;
				}
			}
			@include media(m) {
				&__item {
					width: vw(314);
					margin-bottom: vw(24);
				}
				a{
					padding: 0;
					height: vw(180);
					flex-wrap: wrap;
					align-content: center;
					min-height: 0px;
				}
				&__ico {
					width: vw(90);
					height: vw(90);
					margin: 0 0 vw(8) 0;
				}
				img {
					&.-ico01 {
						width: vw(44);
					}
					&.-ico02 {
						width: 7.5vw;
					}
					&.-ico03 {
						width: vw(45);
						transform: translate(0.3vw,0);
					}
					&.-ico04 {
						width: vw(55);
					}
					&.-ico05 {
						width: vw(41);
					}
					&.-ico06 {
						width: vw(42);
						transform: translate(0.4vw,0);
					}
				}
				&__txt {
					width: 100%;
					span {
						display: none;
					}
					p {
						font-size: vw(26);
						text-align: center;
					}
				}
			}
		}
	}
	&__ing {
		margin-bottom: 135px;
		position: relative;
		@include media(m) {
			margin-bottom: vw(135);
		}
		&__wrap {
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 158px;
				height: 261px;
				top: -18px;
				left: -196px;
				background: url(/parts/img/ing_deco_01_pc.png) center center/cover no-repeat;
			}
		}
		&__l {
			padding: 98px 62px 98px 0;
			width: 450px;
			&__wall {
				content: '';
				display: block;
				width: calc(50% - 64px);
				height: 100%;
				position: absolute;
				top: 0;
				background: #f4f7fa;
				left: 0;
			}
			p {
				line-height: 2;
				margin-bottom: 50px;
			}
		}
		&__bnr {
			li + li {
				margin-top: 10px;
				@include media(m) {
					margin-top: vw(20);
				}
			}
			&__wrap {
				strong {
					display: block;
					border-left: 1px solid $blue;
					padding: 0 0 0 24px;
					margin-bottom: 23px;
					font-weight: 400;
					font-size: 1.8rem;
				}
				@include media(m) {
					strong {
						max-width: 100%;
					}
					&:after {
						content: '';
						display: block;
						position: absolute;
						width: vw(158);
						height: vw(261);
						top: vw(-70);
						left: vw(-34);
						background: url(/parts/img/ing_deco_01_sp.png) center center/cover no-repeat;
					}
				}
			}
			a {
				  display: block;
				img {
					display: block;
					max-width: 384px;
					box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
				}
				@include media(hover) {
					&:hover {
						opacity: 0.7;
					}
				}
				@include media(m) {
					img {
						max-width: 100%;
					}
				}
			}
		}
		&__r {
			width: 512px;
			margin-top: 70px;
		}
		&__list {
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			flex-wrap: wrap;
			li {
				width: calc((100% - (30px * 1)) / 2);
				margin-bottom: 34px;
				
			}
			a {
				display: block;
				@include media(hover) {
					&:hover {
						color: $blue;
						figure {
							img {
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
			figure {
				margin-bottom: 15px;
				overflow: hidden;
				width: 240px;
				height: 240px;
				border-radius: 2px;
				border: 1px solid #e9e9e9;
				overflow: hidden;
				img {    
					width: 240px;
					height: 240px;
					-o-object-fit: cover;
					object-fit: cover;
					font-family: 'object-fit: cover;';
					transition-duration: 0.5s;
				}
			}
			p {
				text-align: center;
				line-height: 1.2;
			}
		}
		@include media(m) {
			.p-top__tit {
				text-align: center;
			}
			&__wrap {
				flex-wrap: wrap;
				strong {
					display: block;
					border-left: 1px solid $blue;
					padding: 0 0 0 vw(25);
					margin-bottom: vw(30);
					font-size: vw(32);
				}
			}
			&__l {
				padding: vw(95) 0 0 0;
				width: 100%;
				&__wall {
					width: 100%;
					height: vw(946);
				}
				p {
					line-height: 1.3;
					margin-bottom: vw(40);
					text-align: center;
				}
			}
			&__r {
				width: 100%;
				order: 0;
				margin: 0 0 vw(85) 0;
			}
			&__list {
				display: flex;
				justify-content: space-between;
				align-content: flex-start;
				flex-wrap: wrap;
				li {
					width: vw(307);
					margin-bottom: vw(30);
				}
				figure {
					margin-bottom: vw(15);
					width: vw(307);
					height: vw(307);
					border-radius: vw(2);
					img {    
						width: vw(307);
						height: vw(307);
					}
				}
				p {
					text-align: center;
				}
			}
			.u-sp {
				display: block;
			}
		}
	}
	&__recipe {
		margin-bottom: 135px;
		background: url(/parts/img/recipe_bg_pc.png) center center/contain no-repeat;
		padding: 80px 0 80px 0;
		.p-top__tit {
			margin-bottom: 20px;
		}
		@media screen and (min-width: 1501px) {
			background: url(/parts/img/recipe_bg_pc.png) center center/cover no-repeat;
		}
		@include media(m) {
			margin-bottom: vw(135);
			background: url(/parts/img/recipe_bg_sp.png) center center/cover no-repeat;
			padding: vw(85) 0 vw(103) 0;
			.p-top__tit {
				margin-bottom: vw(20);
			}
		}
		p {
			margin-bottom: 45px;
			@include media(m) {
				margin-bottom: vw(36);
				line-height: 1.3;
			}
		}
		&__list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li {
				width: calc((100% - (16px * 4)) / 5);
				margin-bottom: 14px;
			}
			a {
				background: $blue;
				border-radius: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 15px;
				height: 58px;
				color: #fff;
				border: 1px solid $blue;
				@include media(hover) {
					&:hover {
						background: #fff;
						color: $blue;
						img {
							opacity: 0;
						}
					}
				}
			}
			span {
				display: block;
				width: 120px;
				text-align: center;
			}
			i {
				display: inline-block;
				margin-right: 5px;
				&.-ico01 {
					width: 19px;
					height: 25px;
					background: url(/common/img/svgico/form/blue/ico_drink.svg) center center/cover no-repeat;
				}
				&.-ico02 {
					width: 22px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_jelly.svg) center center/cover no-repeat;
				}
				&.-ico03 {
					width: 24px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_tablet.svg) center center/cover no-repeat;
				}
				&.-ico04 {
					width: 23px;
					height: 23px;
					background: url(/common/img/svgico/form/blue/ico_soft.svg) center center/cover no-repeat;
				}
				&.-ico05 {
					width: 24px;
					height: 24px;
					background: url(/common/img/svgico/form/blue/ico_hard.svg) center center/cover no-repeat;
				}
				&.-ico06 {
					width: 27px;
					height: 19px;
					background: url(/common/img/svgico/form/blue/ico_powder.svg) center center/cover no-repeat;
				}
				&.-ico07 {
					width: 22px;
					height: 20px;
					background: url(/common/img/svgico/form/blue/ico_bread.svg) center center/cover no-repeat;
				}
				&.-ico08 {
					width: 23px;
					height: 16px;
					background: url(/common/img/svgico/form/blue/ico_meat.svg) center center/cover no-repeat;
				}
				&.-ico09 {
					width: 28px;
					height: 19px;
					background: url(/common/img/svgico/form/blue/ico_sidedish.svg) center center/cover no-repeat;
				}
				&.-ico10 {
					width: 28px;
					height: 22px;
					background: url(/common/img/svgico/form/blue/ico_noodles.svg) center center/cover no-repeat;
				}
			}
			img {
				display: inline-block;
				transition-duration: 0.3s;
				vertical-align: baseline;
				&.-ico01 {
					width: 19px;
				}
				&.-ico02 {
					width: 22px;
				}
				&.-ico03 {
					width: 24px;
				}
				&.-ico04 {
					width: 23px;
				}
				&.-ico05 {
					width: 24px;
				}
				&.-ico06 {
					width: 27px;
				}
				&.-ico07 {
					width: 22px;
				}
				&.-ico08 {
					width: 23px;
					transform: translate(0, -4px);
				}
				&.-ico09 {
					width: 28px;
				}
				&.-ico10 {
					width: 28px;
				}
			}
			@include media(m) {
				li {
					width: vw(315);
					margin-bottom: vw(20);
				}
				a {
					border-radius: vw(2);
					padding: vw(18) vw(33);
					height: vw(93);
					font-size: vw(26);
					justify-content: flex-start;
					white-space: nowrap;
				}
				i {
					background: none!important;
					height: auto!important;
					width: auto!important;
					display: flex;
					align-items: center;
					margin-right: vw(25);
				}
				span {
					width: auto;
					display: inline;
				}
				img {
					&.-ico01 {
						width: vw(29);
					}
					&.-ico02 {
						width: vw(36);
					}
					&.-ico03 {
						width: vw(33);
					}
					&.-ico04 {
						width: vw(34);
						transform: translate(0, 0.3vw);
					}
					&.-ico05 {
						width: vw(34);
						transform: translate(0, 0.3vw);
					}
					&.-ico06 {
						width: vw(40);
					}
					&.-ico07 {
						width: vw(35);
					}
					&.-ico08 {
						width: vw(39);
						transform: translate(0,0);
					}
					&.-ico09 {
						width: vw(42);
					}
					&.-ico10 {
						width: vw(41);
					}
				}
			}
		}
	}
	&__str {
		margin-bottom: 125px;
		&__main {
			position: relative;
		}
		&__wall {
			content: '';
			display: block;
			width: calc(50% + 592px);
			height: 100%;
			position: absolute;
			top: 0;
			background: #f4f7fa;
			right: 0;
		}
		.l-inner {
			padding: 80px 0;
		}
		.p-top__tit__main{
			letter-spacing: 0;
		}
		&__tit {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 32px;
			figure {
				width: 284px;
			}
			> div {
				width: calc(100% - 345px);
			}
		}
		&__list {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
			li {
				padding: 20px 0 15px 0;
				width: calc((100% - (60px * 1)) / 2);
				border-top: 1px solid #dbdee2;
				&:nth-child(n + 3) {
					border-bottom: 1px solid #dbdee2;
				}
			}
			.c-btn {
				padding: 10px 27px;
				span {
					padding-right: 38px;
				}
			}
			&__tit {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;
				h3 {
					font-size: 2.2rem;
					font-weight: 500;
					color: $blue;
					&.-navy {
						color: #3477c6;
					}
					&.-lightgreen {
						color: #70b22b;
					}
					&.-orange {
						color: $orange;
					}
				}
			}
			p {
				font-size: 1.4rem;
			}
		}
		@include media(m) {
			margin-bottom: vw(135);
			.p-top__tit {
				text-align: center;
				margin-bottom: vw(20);
				&__main {
					letter-spacing: 0.1em;
				}
				+ p {
					line-height: 1.3;
				}
			}
			&__wall {
				display: none;
			}
			.l-inner {
				padding: 0;
			}
			&__tit {
				display: block;
				margin-bottom: vw(44);
				text-align: center;
				figure {
					width: vw(397);
					margin: auto;
				}
				> div {
					width: 100%;
					margin-bottom: vw(60);
				}
			}
			&__list {
				display: block;
				border-bottom: 1px solid #dbdee2;
				li {
					padding: vw(30) 0 vw(25) 0;
					width: 100%;
					border-top: 1px solid #dbdee2;
					&:nth-child(n + 3) {
						border-bottom: none;
					}
				}
				.c-btn {
					padding: vw(25) vw(30) vw(28) vw(30);
					font-size: vw(26);
					line-height: 1;
					span {
						padding-right: vw(50);
						&:after {
							top: calc(50% - 0.7vw);
						}
					}
				}
				&__tit {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: vw(16);
					h3 {
						font-size: vw(35);
					}
				}
				p {
					line-height: 1.3;
					font-size: vw(26);
				}
			}
		}
	}
	&__service {
		margin-bottom: 135px;
		position: relative;
		.l-inner {
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 172px;
				height: 170px;
				top: -56px;
				right: -156px;
				background: url(/parts/img/service_deco_01.png) center center/172px no-repeat;
			}
		}
		@include media(m) {
			margin-bottom: vw(135);
			padding: vw(100) 0;
			background: #f1f4f7;
			.p-top__tit {
				margin-bottom: vw(10);
			}
			.l-inner {
				&:before {
					display: none;
				}
			}
			p {
				line-height: 1.3;
			}
		}
		&__txtwrap {
			display: flex;
			justify-content: space-between;
			margin-bottom: 50px;
			@include media(m) {
				margin-bottom: vw(50);
			}
		}
		&__list {
			display: flex;
			li {
				width: 25%;
			}
			a {
				display: flex;
				align-items: flex-end;
				flex-wrap: wrap;
				position: relative;
				height: 375px;
				color: #fff;
				align-content: flex-end;
				overflow: hidden;
				&:before {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: url(/parts/img/service_dotted_pc.png) center bottom/4px 4px;
					content: '';
				}
				strong {
					display: block;
					width: 100%;
					font-size: 2.2rem;
					position: relative;
					font-weight: 400;
					margin-bottom: 10px;
					padding-right: 40px;
					i {
						content: '';
						display: block;
						position: absolute;
						width: 27px;
						height: 27px;
						top: 5px;
						right: 0;
						background: url(/parts/img/ico_servicearrow.png) center center/cover no-repeat;
					}
				}
				p {
					width: 100%;
					font-size: 1.4rem;
					line-height: 1.8;
				}
				div {
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					padding: 0 30px 24px 30px;
				}
				figure {
					width: 100%;
					overflow: hidden;
					img {
						width: 100%;
						height: 375px;
						-o-object-fit: cover;
						object-fit: cover;
						font-family: 'object-fit: cover;';
						transition-duration: 0.5s;
					}
				}
				@include media(hover) {
					&:hover {
						figure {
							img {
								transform: scale(1.1, 1.1);
							}
						}
					}
				}
			}
			@include media(m) {
				width: vw(650);
				margin: 0 auto vw(55) auto;
				flex-wrap: wrap;
				li {
					width: 50%;
				}
				a {
					height: vw(325);
					&:before {
						display: none;
					}
					&.-img01 {
						background: url(/parts/img/service_img_01_sp.jpg) center center/cover no-repeat;
					}
					&.-img02 {
						background: url(/parts/img/service_img_02_sp.jpg) center center/cover no-repeat;
					}
					&.-img03 {
						background: url(/parts/img/service_img_03_sp.jpg) center center/cover no-repeat;
					}
					&.-img04 {
						background: url(/parts/img/service_img_04_sp.jpg) center center/cover no-repeat;
					}
					p {
						display: none;
					}
					strong {
						font-size: vw(30);
						font-weight: 400;
						padding-right: vw(50);
						width: 100%;
						line-height: 1.3;
						margin-bottom: 0;
						white-space: nowrap;
						i {
							width: vw(38);
							height: vw(38);
							top: vw(0);
							right: 0;
							background: url(/parts/img/ico_servicearrow_sp.png) center center/cover no-repeat;
						}
					}
					div {
						padding: vw(30);
					}
					figure {
						display: none;
					}
				}
			}
		}
	}
	&__news {
		margin-bottom: 75px;
		&__titwrap {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 45px;
			.p-top__tit {
				margin-bottom: 0;
			}
			
			@include media(m) {
				margin-bottom: vw(50);
				align-items: center;
			}
		}
		&__btn {
			width: 182px;
			height: 42px;
			display: flex;
			border: 1px solid #282828;
			border-radius: 2px;
			justify-content: center;
			align-items: center;
			font-size: 1.4rem;
			span {
				position: relative;
				z-index: 1;
				transition-duration: 0.5s;
				display: inline-block;
				padding-right: 24px;
				&:after {
					content: '';
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 0;
					top: calc(50% - 5px);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto no-repeat;
					transition-duration: 0.3s;
				}
			}
			@include media(m) {
				width: vw(249);
				height: vw(88);
				span {
					padding-right: vw(34);
					&:after {
						margin-left: 0;
						top: calc(50% - vw(1));
						width: vw(10);
						height: vw(19);
					}
					
				}
			}
			@include media(hover) {
				&:hover {
					background: $blue;
					border: 1px solid $blue;
					span {
						color: #fff;
						&:after {
							background: url(/common/img/ico_arrownews_white.png) center bottom/100% auto no-repeat;
						}
					}
				}
			}
		}
		&__list {
			margin-bottom: 30px;
			border-top: 1px solid #ededed;
			&__item {
				border-bottom: 1px solid #ededed;
			}
			&__tag {
				width: 78px;
				border-radius: 3px;
				display: flex;
				height: 20px;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 1.2rem;
				line-height: 1;
				margin-right: 25px;
				&.-news {
					background: #58b5c9;
				}
				&.-recruit {
					background: #258ac7;
				}
				&.-exhibition {
					background: #55b755;
				}
			}
			&__txt {
				padding: 22px 0 22px 10px;
				position: relative;
				justify-content: space-between;
				align-items: flex-start;
				display: flex;
				p {
					width: calc(100% - 190px);
					font-size: 1.4rem;
				}
				time {
					font-family: 'Montserrat', sans-serif;
					font-size: 1.2rem;
					font-weight: 600;
					letter-spacing: 0.08em;
				    width: 78px;
					color: #666;
				}
			}
			a {
				display: block;
				width: 100%;
				position: relative;
				padding-right: 63px;
				&:after {
					content: '';
					display: inline-block;
					margin-left: 10px;
					position: absolute;
					right: 20px;
					top: calc(50% - 5px);
					width: 5px;
					height: 9px;
					background: url(/common/img/ico_arrownews_black.png) center bottom/100% auto;
					transition-duration: 0.3s;
				}
				@include media(hover) {
					&:hover {
						color: $blue;
						&:after {
							background: url(/common/img/ico_arrownews_blue.png) center bottom/100% auto;
							transition-duration: 0.3s;
						}
					}
				}
			}
		}
		@include media(m) {
			margin-bottom: vw(150);
			&__list {
				margin-bottom: vw(30);
				&__tag {
					width: vw(206);
					height: vw(42);
					font-size: vw(24);
				}
				&__txt {
					padding: vw(34) 0 vw(30) 0;
					flex-wrap: wrap;
					justify-content: flex-start;
					p {
						width: 100%;
						margin-top: vw(15);
						font-size: vw(28);
						line-height: 1.3em;
						overflow: hidden;
						max-height: vw(74);
					}
					time {
						font-size: vw(27);
						margin-right: vw(23);
						font-weight: 500;
						width: auto;
					}
				}
				a {
					padding-right: vw(60);
					&:after {
						width: vw(10);
						height: vw(18);
						position: absolute;
						right: 0;
						top: calc(50% - -4vw);
					}
				}
			}
		}
	}
	
	main {
		margin-top: 162px;
		@include media(m) {
			margin-top: vw(182);
		}
	}
	.l-header__subnav {
		display: none!important;
	}
	
}
.p-6206 {
	main {
		margin-top: 162px;
		@include media(m) {
			margin-top: vw(182);
		}
	}
	.l-header__subnav {
		display: none!important;
	}
}