.c-btn {
	display: flex;
	position: relative;
	padding: 15px 48px;
	align-items: center;
	background: $blue;
	border: 1px solid $blue;
	transition-duration: 0.5s;
	overflow: hidden;
	line-height: 1.4em;
	border-radius: 2px;
	cursor: pointer;
	clear: both;
	&.u-aC {
		justify-content: center;
		span {
			display: inline-block;
			width: auto;
		}
	}
	&.-mw515 {
		max-width: 515px;
		margin: auto;
	}
	&.-pc280 {
		width: 280px;
	}
	&.-mw400 {
		max-width: 400px;
	}
	&.-navy {
		background: #3477c6;
		border: 1px solid #3477c6;
	}
	&.-lightgreen {
		background: #70b22b;
		border: 1px solid #70b22b;
	}
	&.-orange {
		background: $orange;
		border: 1px solid $orange;
	}
	span {
		position: relative;
		z-index: 1;
		color: #fff;
		transition-duration: 0.5s;
		display: block;
		padding-right: 53px;
		width: 100%;
		&:after {
			content: '';
			display: block;
			width: 22px;
			height: 7px;
			right: -18px;
			top: calc(50% - 4px);
			position: absolute;
			background: url(/common/img/btn_arrow_white.png) center bottom/cover no-repeat;
			transform: translate(-20px,0);
			transition-duration: 0.5s;
		}
	}
	@include media(m) {
		text-align: center;
		justify-content: center;
		padding: vw(26) vw(40);
		line-height: 1.3;
		span {
			display: inline-block;
			width: auto;
			padding-right: vw(64);
			&:after {
				width: vw(34);
				height: vw(11);
				right: vw(-40);
				top: calc(50% - 1vw);
				background: url(/common/img/btn_arrow_sp_white.png) center bottom/cover no-repeat;
			}
		}
	}
    @include media(hover) {
		&:hover {
			background: #fff;
			span {
				color: $blue;
				&:after {
					background: url(/common/img/btn_arrow_blue.png) center bottom/cover no-repeat;
				}
			}
			&.-navy {
				span {
					color: #3477c6;
					&:after {
						background: url(/common/img/btn_arrow_navy.png) center bottom/cover no-repeat;
					}
				}
			}
			&.-lightgreen {
				span {
					color: #70b22b;
					&:after {
						background: url(/common/img/btn_arrow_lightgreen.png) center bottom/cover no-repeat;
					}
				}
			}
			&.-orange {
				span {
					color: $orange;
					&:after {
						background: url(/common/img/btn_arrow_orange.png) center bottom/cover no-repeat;
					}
				}
			}
		}
    }
	&.-black {
		background: #282828;
		border: 1px solid #282828;
		@include media(hover) {
			&:hover {
				background: $blue;
				border: 1px solid $blue;
				color: #fff;
				span {
					color: #fff;
					&:after {
						background: url(/common/img/btn_arrow_white.png) center bottom/cover no-repeat;
					}
				}
			}
		}
	}
	&.-grade {
		border: 1px solid rgba(255,255,255,0);
		border-right: 1px solid #38ebba;
		border-left: 1px solid #6fb1d2;
	  background-image: linear-gradient( 270deg, rgb(56,235,186) 0%, rgb(111,177,210) 100%);
	  background-image: -moz-linear-gradient( 270deg, rgb(56,235,186) 0%, rgb(111,177,210) 100%);
	  background-image: -webkit-linear-gradient( 270deg, rgb(56,235,186) 0%, rgb(111,177,210) 100%);
	  background-image: -ms-linear-gradient( 270deg, rgb(56,235,186) 0%, rgb(111,177,210) 100%);
		@include media(hover) {
			&:hover {
				background: #fff;
				border: 1px solid $blue;
				border-right: 1px solid $blue;
				border-left: 1px solid $blue;
				span {
					color: $blue;
					&:after {
						background: url(/common/img/btn_arrow_blue.png) center bottom/cover no-repeat;
					}
				}
			}
		}
	}
	&.-plus {
		span {
			&:after {
				display: none;
			}
		}
		i {
			width: 13px;
			height: 13px;
			display: inline-block;
			position: absolute;
			top: calc(50% - 6px);
			right: 0;
			&:before {
				content: '';
				display: block;
				width: 13px;
				height: 1px;
				background: #fff;
				right: 0;
				top: 6px;
				position: absolute;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				height: 13px;
				width: 1px;
				background: #fff;
				right: 6px;
				top: 0;
			}
		}
		&.is-active {
			i {
				&:after {
					display: none;
				}
			}
		}
		&.-black {
			@include media(hover) {
				&:hover {
					i {
						&:after,&:before {
							background: #fff;
						}
					}
				}
			}	
		}
	}
	&[target="_blank"] {
		span {
			i {
				width: 11px;
				height: 8px;
				position: absolute;
				display: inline-block;
				margin-left: 8px;
				border: 1px solid #fff;
				transform: translate(0,-2px);
				right: 0;
				top: calc(50% - 4px);
				transition-duration: 0.5s;
				&:before {
					content: '';
					width: 11px;
					height: 8px;
					border-bottom: 1px solid #fff;
					border-left: 1px solid #fff;
					position: absolute;
					bottom: -3px;
					left: -3px;
					transition-duration: 0.5s;
				}
			}
			&:before,&:after {
				display: none;
			}
		}
		@include media(hover) {
			&:hover {
				span {
					i {
						width: 11px;
						height: 8px;
						position: absolute;
						display: inline-block;
						margin-left: 8px;
						border: 1px solid $blue;
						transform: translate(0,-2px);
						right: 0;
						top: calc(50% - 4px);
						&:before {
							content: '';
							width: 11px;
							height: 8px;
							border-bottom: 1px solid $blue;
							border-left: 1px solid $blue;
							position: absolute;
							bottom: -3px;
							left: -3px;
						}
					}
					&:before {
						display: none;
					}
				}
			}
		}
		@include media(m) {
			span {
				i {
					width: vw(24);
					height: vw(18);
					position: absolute;
					display: inline-block;
					margin-left: vw(10);
					border: 1px solid #fff;
					top: calc(50% - 1vw);
					&:before {
						content: '';
						width: vw(24);
						height: vw(18);
						border-bottom: 1px solid #fff;
						border-left: 1px solid #fff;
						position: absolute;
						bottom: -3px;
						left: -3px;
					}
				}
			}
		}
		&[href$=".pdf"] {
			span {
				i {
					border: none;
					&:before {
						border: none;
					}
				}
				position: relative;
				z-index: 1;
				transition-duration: 0.5s;
				padding-right: 23px;
				&:before {
					content: '';
					display: block;
					width: 11px;
					height: 13px;
					right: 0;
					top: calc(50% - 6px);
					position: absolute;
					background: url(/common/img/ico_pdf_white.png) center bottom/11px auto no-repeat;
					transition-duration: 0.5s;
					border: none;
				}
			}
			
			@include media(hover) {
				&:hover {
					span {
						i {
							border: none;
							&:before {
								border: none;
							}
						}
						&:before {
							display: block;
							background: url(/common/img/ico_pdf_blue.png) center bottom/11px auto no-repeat;
						}
					}
				}
			}
			@include media(m) {
				span {
					padding-right: vw(43);
					&:after {
						display: none;
					}
					&:before {
						opacity: 1;
						width: vw(20);
						height: vw(25);
						top: calc(50% - 1.6vw);
						transform: translate(0,0);
						background: url(/common/img/ico_pdf_white.png) center bottom/vw(20) auto no-repeat;
					}
				}
			}
		}
	}
}